import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

const Popup = (props) => {
  const { children, openPopup , key } = props;
  return (
    <Dialog open={openPopup} key={key}>
      {/* <DialogTitle>
        </DialogTitle> */}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Popup;
