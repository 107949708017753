import { Button, TextField } from "@mui/material";
import React, { useState } from "react";

const DriverVehicleInfo = ({
  vehicleInfo,
  handleVehicleInfo,
  vehicleImageFile,
  handleVehicleImageInput
}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = React.createRef();

  const handleFileInput = () => {
    fileInputRef.current.click();
  };

  const handleImage = (file) => {
    handleVehicleImageInput(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  handleImage(vehicleImageFile);

  const [nullErrors, setNullErrors] = useState({})
  const isEmpty = (field, value) => {
    if (value.length === 0) {
      setNullErrors({
        ...nullErrors,
        [field]: true
      })
    } else {
      setNullErrors({
        ...nullErrors,
        [field]: false
      })
    }
  }
  return (
    <div className="rider-profile-body">
      <p style={{ fontSize: 24, fontWeight: 600 }}>Vehicle Information</p>
      <div>
        {(vehicleInfo.image || imagePreview) && (
          <img src={imagePreview || vehicleInfo.image} alt="Vehicle Picture" height="7%" width="30%"></img>
        )}
        <br></br>
        <Button
          variant="contained"
          onClick={handleFileInput}
          style={{
            color: "white",
            background: "#0C66E4",
            textTransform: "none",
          }}
        >
          Upload vehicle picture
        </Button>
        <input
          style={{ display: "none" }}
          ref={fileInputRef}
          type="file"
          name="image"
          onChange={(e)=>{handleImage(e.target.files[0])}}
        /><br></br><br></br>
        <i>Vehicle photo will only be displayed when a ride offer has been accepted</i>
      </div>
      <div className="form-row">
        <div className="form-column">
          <p>Make of the vehicle</p>
          <p>Model of the vehicle</p>
          <p>Year of the vehicle</p>
          <p>Vehicle license plate number</p>
          <p>Specifications</p>
        </div>
        <div className="form-column">
          <TextField
            error={nullErrors.make}
            helperText={nullErrors.make ? "Vehicle make cannot be empty" : ''}
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="Nissan"
            value={vehicleInfo.make}
            onChange={(e) => {
              handleVehicleInfo("make", e.target.value);
            }}
            onBlur={(e) => {
              isEmpty("make", e.target.value);
            }}
          />
          <TextField
            error={nullErrors.model}
            helperText={nullErrors.model ? "Vehicle model cannot be empty" : ''}
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="Altima"
            value={vehicleInfo.model}
            onChange={(e) => {
              handleVehicleInfo("model", e.target.value);
            }}
            onBlur={(e) => {
              isEmpty("model", e.target.value);
            }}
          />
          <TextField
            error={nullErrors.year}
            helperText={nullErrors.year ? "Vehicle year cannot be empty" : ''}
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            type="number"
            placeholder="2009"
            value={vehicleInfo.year}
            onChange={(e) => {
              handleVehicleInfo("year", e.target.value);
            }}
            onBlur={(e) => {
              isEmpty("year", e.target.value);
              if (parseInt(e.target.value)<=0){
                setNullErrors({
                  ...nullErrors,
                  year: true
                })
              }
            }}
          />
          <TextField
            error={nullErrors.numberPlate}
            helperText={nullErrors.numberPlate ? "Vehicle number plate is required" : ''}
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="LSC234"
            value={vehicleInfo.numberPlate}
            onChange={(e) => {
              handleVehicleInfo("numberPlate", e.target.value);
            }}
            onBlur={(e) => {
              isEmpty("numberPlate", e.target.value);
            }}
          />
          <TextField
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="e.g., number of doors, hatchback, trunk, air conditioning" 
            value={vehicleInfo.specification}
            onChange={(e) => {
              handleVehicleInfo("specification", e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverVehicleInfo;
