import { Button, IconButton, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const RiderBuddyShare = ({ id, driverID, tripProgress, handleTripProgress }) => {
  const [timeBankBuddy, setTimeBankBuddy] = useState("");
  const [buddyPreference, setBuddyPreference] = useState("Yes");
  const [contact, setContact] = useState("");
  const [driver, setDriver] = useState({});
  const [tripRequest, setTripRequest] = useState({});
  const [tripSummary, setTripSummary] = useState("");
  const [driverContact, setDriverContact] = useState("");
  const [contactDetails, setContactDetails] = useState("");

  const handleSelect = (e) => {
    setTimeBankBuddy(e.target.value);
    handleTripProgress("emergencyContactBuddy", e.target.value);
  };

  const handleBuddyPreference = (e) => {
    setBuddyPreference(e.target.value);
    handleTripProgress("tripShare", e.target.value);
  };

  const riderID = window.sessionStorage.getItem("riderID");

  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [emergencyContactElements, setEmergencyContactElements] = useState([]);
  const [emergencyContactOptions, setEmergencyContactOptions] = useState([]);

  useEffect(() => {
    const getTrip = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/trip-request/" + id)
        .then((response) => {
          setTripRequest({
            ...response.data,
          });

          setTripSummary(`I am going on a trip from ${response.data.pickup} to ${response.data.destination} on ${response.data.date}`);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
        });
    };
    getTrip();
  }, []);

  useEffect(() => {
    const getDriver = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/driver/" + driverID)
        .then((response) => {
          setDriver({
            ...response.data,
          });
          setContactDetails(`${response.data.profile.name} if needed at ${response.data.profile.email} or ${response.data.profile.mobile}`)
          setDriverContact(`with the driver ${response.data.profile.name} who can be contacted at ${response.data.profile.mobile}`);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
        });
    };
    getDriver();
  }, []);

  useEffect(() => {
    setEmergencyContactElements([]);
    setEmergencyContactOptions([]);
    const getRiderProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/rider/" + riderID)
        .then((response) => {
          handleTripProgress("riderID", riderID);
          setEmergencyContacts(response.data.emergencyContacts);
          setContact(response.data.profile.mobile);
          let contacts = [];

          let contactOptions = [];
          for (let i = 0; i < response.data.emergencyContacts.length; i++) {
            contacts.push(
              <p key={response.data.emergencyContacts[i].id}>
                {i + 1}. {response.data.emergencyContacts[i].name}, {response.data.emergencyContacts[i].mobile},{" "}
                {response.data.emergencyContacts[i].email}
              </p>,
              <br></br>
            );

            contactOptions.push(
              <MenuItem value={response.data.emergencyContacts[i].id}>
                {" "}
                {response.data.emergencyContacts[i].name}{" "}
              </MenuItem>
            );
          }

          setEmergencyContactElements(contacts);
          setEmergencyContactOptions(contactOptions);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
        });
    };
    getRiderProfile();
  }, []);

  const [contactError, setContactError] = useState(null);
  const checkContact = (value) => {
    if (!/^[0-9]{10}$/.test(value)) {
      setContactError(true);
    } else {
      setContactError(false);
    }
  };

  return (
    <div className="rider-profile-body">
      <div className="form-row-1">
        <p style={{ fontSize: 24, fontWeight: 600 }}>Start Your Trip</p>
        {/* <p style={{ color: "#3579F6" }}>Share Link</p> */}
      </div>
      {/* {tripRequest.self_booking==="Yes" &&
      (<div>
      <p style={{ fontSize: 16, fontWeight: 600 }}>
        Share trip status
      </p>
      <div className="form-row-1">
        <p>Would you like to share your trip details with your emergency contact?</p>
        <Select
          className="text-component"
          value={buddyPreference}
          onChange={handleBuddyPreference}
        >
          <MenuItem value={"Yes"}>Yes</MenuItem>
          <MenuItem value={"No"}>No</MenuItem>
        </Select>
      </div>
      {buddyPreference === "Yes" && (<div><div className="form-row-1">
        <p>Who is your preferred buddy for this trip?</p>
        <Select
          className="text-component"
          value={timeBankBuddy}
          onChange={handleSelect}
        >
          {emergencyContactOptions}
        </Select>
      </div>
        <p style={{ fontSize: 16, fontWeight: 600 }}>My emergency contacts</p><br></br>
        <div>{emergencyContactElements}</div>
      </div>
      )}
      </div>)}
      {buddyPreference === "No" && (
        <div className="form-row-1">
          <p>Emergency Contact Information</p>
          <TextField
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={timeBankBuddy}
            placeholder="Alex Antony"
            onChange={(e) => {
              setTimeBankBuddy(e.target.value);
              handleTripProgress("tripBuddy", e.target.value);
            }}
          />
        </div>)} */}
        {/* {buddyPreference === "Yes" && (<div> */}
      <div>
        <p style={{ fontSize: 16, fontWeight: 600 }}>Ride Details    <Button color="primary"onClick={() => { navigator.clipboard.writeText(tripSummary + driverContact) }}>Copy</Button></p>
        <p>{tripSummary} {driverContact}</p><br></br>
        <p><i>Copy and paste the ride details into an email or text</i></p>
      </div>
      {/* )} */}
      <div>
        <p style={{ fontSize: 16, fontWeight: 600 }}>
          Contact Details
        </p><br></br>
        <div className="form-row-1">
          <p>Is this the correct phone number to contact you for the trip? </p>
          <TextField
            className="text-component"
            error={contactError}
            helperText={contactError ? "Please enter a valid 10 digit phone number without any extra characters" : ''}
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={contact}
            placeholder="+1 801-133-7946"
            onChange={(e) => {
              setContact(e.target.value);
              handleTripProgress("mobile", e.target.value);
            }}
            onBlur={(e) => {
                checkContact(e.target.value);
            }}
          />
        </div>
        {/* <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          onClick={handleContact}
          style={{ color: "black", background: "#091E4206" }}
        >
          Add emergency contact
        </Button>
      </div> */}
        <p>Please reach out to the driver {contactDetails}</p>
      </div>
    </div>
  );
};

export default RiderBuddyShare;
