import Button from "@atlaskit/button";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import RiderTripRequestInfo from "./riderTripRequestInfo";
import RiderTripAccomodations from "./riderTripAccomodations";
import RiderTripConfirmation from "./riderTripConfirmation";
import axios from "axios";
import Alert from "@mui/material/Alert";

const CreateTripRequest = () => {
  const [page, setPage] = useState(0);
  const [nextButton, setNextButton] = useState("Next");

  const FormTitles = ["Trip Request", "Accomodations", "Confirmation"];
  const history = useHistory();

  const [apiError, setAPIError] = useState(false);

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  const [tripRequest, setTripRequest] = useState({
    date: "",
    pickup: "",
    appointmentTime: "",
    nearbyIntersection: "",
    destination: "",
    type: "One-way trip",
    waitTime: "Less than an hour",
    selfBooking: "Yes",
    riderDetails: {},
    buddyMatchup: "No",
    numberOfPassengers: 1,
    numberOfStops: 0,
    stopWaitTimes: [],
    stopPoints: [],
    comments: "",
    riderID: 0,
    pickupLatitude: 39.1653,
    pickupLongitude: -86.526,
  });

  function handleTripRequest(field, value) {
    setTripRequest({
      ...tripRequest,
      [field]: value,
    });
  }

  const [tripInfoAlert, setTripInfoAlert] = useState(false);
  const [tripAccomodationsAlert, setTripAccomodationsAlert] = useState(false);

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <div>
          <RiderTripRequestInfo
            tripRequest={tripRequest}
            handleTripRequest={handleTripRequest}
          />
          <br></br>
          {tripInfoAlert && (
            <Alert severity="error">{"The fields pick-up location, drop-off location, nearby intersection, pick-up date, time and trip duration are required. Please provide valid values"}</Alert>
          )}
        </div>
      );
    } else if (page === 1) {

      console.log(tripRequest)
      return (
        <div>
          <RiderTripAccomodations
            tripRequest={tripRequest}
            handleTripRequest={handleTripRequest}
          />
          <br></br>
          {tripAccomodationsAlert && (
            <Alert severity="error">{"Please provide a valid value for number of passengers for the trip"}</Alert>
          )}
        </div>
      );
    } else {
      return <RiderTripConfirmation tripRequest={tripRequest} />;
    }
  };

  const handleBack = () => {
    setPage((currPage) => currPage - 1);

    if (page === 2) {
      setNextButton("Next");
    }
  };

  const manageTripRequest = async () => {
    try {
      tripRequest.riderID = window.sessionStorage.getItem("riderID");
    } catch (e) {
      //TODO: Throw alert
      console.log("Failed");
    }

    if (tripRequest.selfBooking === "Yes") {
      tripRequest.riderDetails = null;
    }

    await axios
      .post(
        "https://iuhw-app.hourworld.org/trip-request",
        tripRequest
      )
      .then((response) => {
        tripRequest.id = response.data.id;
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });

    const riderChecklist = [
      {
        key: "Confirm Appointment",
        value: false,
      },
      {
        key: "Agree on gas money",
        value: false,
      },
      {
        key: "Confirm parking location",
        value: false,
      }
    ]

    await axios
      .get(`https://iuhw-app.hourworld.org/rider/${tripRequest.riderID}`)
      .then((response) => {
        if (response.data.preferences.covid.wearMask === true) {
          riderChecklist.push({
            key: "Wear a face mask",
            value: false
          })
        }
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });

    await axios
      .post(
        `https://iuhw-app.hourworld.org/trip-request/${tripRequest.id}/checklist`,
        {
          trip_id: tripRequest.id,
          rider_checklist: riderChecklist
        }
      )
      .then((response) => {
        history.push("/rider-my-trips");
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });

  };

  const handleNext = () => {
    console.log(tripRequest);
    if (page === 0) {
      if (
        tripRequest.pickup === "" ||
        tripRequest.nearbyIntersection === "" ||
        tripRequest.destination === "" ||
        tripRequest.date === "" ||
        (tripRequest.numberOfStops > 0 && (tripRequest.stopPoints.includes("") || tripRequest.stopWaitTimes.includes("")))
      ) {
        setTripInfoAlert(true);
        return;
      } else {
        setTripInfoAlert(false);
      }
    }

    if (page === 1) {
      if (
        tripRequest.numberOfPassengers === null ||
        parseInt(tripRequest.numberOfPassengers) < 0 || parseInt(tripRequest.numberOfPassengers) > 20
      ) {
        setTripAccomodationsAlert(true);
        return;
      } else {
        setTripAccomodationsAlert(false);
      }
    }

    setPage((currPage) => currPage + 1);

    if (page === 1) {
      setNextButton("Post request to Timebank");
    }

    if (page === 2) {
      manageTripRequest();
      // history.push("/rider-my-trips");
    }
  };

  return (
    <div className="profile">
      <div className="body">
        <p className="heading" style={{ fontWeight: "bold" }}>
          Create Trip Request
        </p>
        <br />
        <p className="subHeading">
          Provide your trip destination and find people that can help you with
          the request.
        </p>
      </div>

      <div className="profile-home">
        <div className="progressbar">
          <div
            style={{
              width: page === 0 ? "33%" : page === 1 ? "66%" : "100%",
            }}
          ></div>
        </div>
        <div className="inline-text">
          <div
            style={{
              color: page === 0 ? "#3579F6" : "black",
              marginLeft: "0%",
            }}
          >
            {FormTitles[0]}
          </div>
          <div
            style={{
              color: page === 1 ? "#3579F6" : "black",
              marginLeft: "15%",
            }}
          >
            {FormTitles[1]}
          </div>
          <div
            style={{
              color: page === 2 ? "#3579F6" : "black",
              marginLeft: "15%",
            }}
          >
            {FormTitles[2]}
          </div>
        </div>
      </div>
      <div className="form-container">
        <div className="form-body">{PageDisplay()}</div>
        <div className="form-footer">
          <Button isDisabled={page === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            appearance="primary"
            // isDisabled={page === FormTitles.length - 1}
            onClick={handleNext}
          >
            {nextButton}
          </Button>
        </div>
      </div>
      {apiError && (
        <Alert severity="error">
          Request to server failed. Please try again
        </Alert>
      )}
    </div>
  );
};

export default CreateTripRequest;
