import { Select, MenuItem, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { useHistory } from "react-router-dom";

const DriverPreferences = ({
  covidPreference,
  handleCovidPreference,
  preferences,
  handlePreferences,
}) => {
  const history = useHistory();
  try {
    if (window.sessionStorage.getItem("bearerToken") === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  return (
    <div className="rider-profile-body">
      <p style={{ fontSize: 24, fontWeight: 600 }}>Trip Preferences</p>
      <div className="form-row">
        <div className="form-column">
          <p>
            {" "}
            What are your health-related safety preferences?
            <br />
            <br />
            Check all that apply
            <br></br>
            <br></br>
          </p>
          <p>What is your smoking preference?</p>
          <p>What is your music preference?</p>
          <p>Would you like gas money?</p>
          <p>Do you have any of the following allergies?</p>
          {preferences.allergies==="Other" &&(<p>Other Allergies</p>)}
        </div>
        <div className="form-column">
          <div className="form-column-1">
            <FormControlLabel
              control={<Checkbox checked={covidPreference.wearMask} />}
              label="I will wear a mask"
              onChange={(e) => {
                handleCovidPreference("wearMask", e.target.checked);
              }}
            />
            <FormControlLabel
              control={<Checkbox checked={covidPreference.driverWearMask} />}
              label="I want the rider to wear a mask"
              onChange={(e) => {
                handleCovidPreference("riderWearMask", e.target.checked);
              }}
            />
          </div>
          <Select
            className="text-component"
            value={preferences.smoke}
            onChange={(e) => {
              handlePreferences("smoke", e.target.value);
            }}
            style={{ height: 40 }}
          >
            <MenuItem value={"I don't mind if the rider smokes"}>
              I don't mind if the rider smokes
            </MenuItem>
            <MenuItem value={"I mind if the rider smokes"}>
              I mind if the rider smokes
            </MenuItem>
          </Select>
          <Select
            className="text-component"
            value={preferences.music}
            onChange={(e) => {
              handlePreferences("music", e.target.value);
            }}
            style={{ height: 40 }}
          >
            <MenuItem value={"On"}>On</MenuItem>
            <MenuItem value={"Off"}>
              Off
            </MenuItem>
            <MenuItem value={"No preference"}>No preference</MenuItem>
          </Select>
          <Select
            className="text-component"
            value={preferences.gasMoney}
            onChange={(e) => {
              handlePreferences("gasMoney", e.target.value);
            }}
            style={{ height: 40 }}
          >
            <MenuItem value={"Would be nice, but not necessary"}>Would be nice, but not necessary</MenuItem>
            <MenuItem value={"Yes"}>Yes</MenuItem>
            <MenuItem value={"No"}>No</MenuItem>
          </Select>
          <Select
            className="text-component"
            value={preferences.allergies}
            onChange={(e) => {
              handlePreferences("allergies", e.target.value);
            }}
            style={{ height: 40 }}
          >
            <MenuItem value={"Smoke"}>Smoke</MenuItem>
            <MenuItem value={"Strong odors or perfumes"}>Strong odors or perfumes</MenuItem>
            <MenuItem value={"Animals"}>Animals</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
            <MenuItem value={"None"}>None</MenuItem>
          </Select>
          {preferences.allergies==="Other" &&(<TextField
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={preferences.otherAllergies}
            placeholder="AC"
            onChange={(e) => {
              handlePreferences("otherAllergies", e.target.value);
            }}
          />)}
        </div>
      </div>
    </div>
  );
};

export default DriverPreferences;
