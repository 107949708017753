import { Button } from "@mui/material";
import DriverPreparation from "./driverPreparation";
import RiderPreparation from "./riderPreparation";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
const PreviewChecklist = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const rider = queryParams.get('rider');
    const driver = queryParams.get('driver');
    const { id } = useParams();
    const history = useHistory();

    return (
        <div className="profile">
            <div className="form-container">
                <div className="form-body">
                    {rider === "true" && (<RiderPreparation id={id} />)}
                    {driver === "true" && (<DriverPreparation id={id} />)}
                </div>
                <br></br>
                <Button
                    variant="contained"
                    onClick={() => { history.goBack() }}
                    style={{
                        color: "black",
                        background: "#091E4206",
                        textTransform: "none",
                    }}
                >
                    Go back
                </Button>
            </div>
        </div>
    );
};

export default PreviewChecklist;