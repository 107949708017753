import { Button, MenuItem, Rating, Select } from "@mui/material";
import ReactRoundedImage from "react-rounded-image";
import { useEffect, useState } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";

const DriverAchievements = () => {
  const [skillPreference, setSkillPreference] = useState("Transportation");
  const [tripGoals, setTripGoals] = useState("10");
  const [positiveReviews, setPositiveReviews] = useState("5");
  const driverID=window.sessionStorage.getItem("driverID");
  const history = useHistory();
  const [apiError, setAPIError] = useState(false);
  const handleSkillPreference = (e) => {
    setSkillPreference(e.target.value);
  };

  const handleTripGoals = (e) => {
    setTripGoals(e.target.value);
  };

  const handlePositiveReviews = (e) => {
    setPositiveReviews(e.target.value);
  };
  const [profile, setProfile] = useState({});
  
  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;
  
  useEffect(() => {
    const getDriverProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/driver/" + driverID + "/profile")
        .then((response) => {
          setProfile({
            ...response.data.profile,
          });
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getDriverProfile();
  }, []);


  return (
    <div className="profile">
      <div className="body" style={{ paddingBottom: "3rem" }}>
        <p className="heading" style={{ fontWeight: "bold" }}>
          Driver Achievements
        </p>
        <br />
        <p className="subheading">
          Thank you so much for helping people in our community!
        </p>
      </div>
      <p style={{ fontSize: "20px", fontWeight: "bold" }}>
        Let's review your achievements
      </p>
      <div className="split-body">
        <div>
          <div style={{ paddingBottom: "3rem" }}>
            <div className="driver-box">
              <p style={{ fontWeight: "bold" }}>Driver Profile</p>
              <p> You've filled out your driver profile. Great job!</p>
            </div>
            <br></br>
            <div className="driver-box">
              <p style={{ fontWeight: "bold" }}>TimeBank Record</p>
              <p> Make 10 completed trips</p>
            </div>
          </div>
          <div style={{ paddingBottom: "3rem" }}>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                paddingBottom: "2rem",
              }}
            >
              Set trip goals
            </p>
            <div style={{ display: "flex", alignItems: "row", gap: "40%" }}>
              <div className="form-column">
                <p>Select a skill</p>
                <p>Set your goals for the trip</p>
                <p>Set your positive reviews goal</p>
              </div>
              <div className="form-column">
                <Select
                  className="text-component"
                  value={skillPreference}
                  onChange={handleSkillPreference}
                >
                  <MenuItem value={"Transportation"}>Transportation</MenuItem>
                  <MenuItem value={"Transport Assist"}>
                    Transport Assist
                  </MenuItem>
                </Select>
                <Select
                  className="text-component"
                  value={tripGoals}
                  onChange={handleTripGoals}
                >
                  <MenuItem value={"5"}>5</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"15"}>15</MenuItem>
                </Select>
                <Select
                  className="text-component"
                  value={positiveReviews}
                  onChange={handlePositiveReviews}
                >
                  <MenuItem value={"5"}>5</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"15"}>15</MenuItem>
                </Select>
              </div>
            </div>
            <Button
              variant="contained"
              style={{
                color: "white",
                background: "#0C66E4",
                textTransform: "none",
              }}
            >
              Save
            </Button>
          </div>

          <div style={{ paddingBottom: "3rem" }}>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                paddingBottom: "2rem",
              }}
            >
              TimeBank driver skills training certificate
            </p>
            <div className="driver-box">
              <div style={{ display: "flex", alignItems: "row", gap: "1rem" }}>
                <div>
                  <p style={{ fontWeight: "bold" }}>Complete certificate</p>
                  <p>
                    {" "}
                    Receive a certificate after completing a free 2-hour driver
                    training course.
                  </p>
                </div>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    background: "#0C66E4",
                    textTransform: "none",
                  }}
                >
                  Take course
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="driver-box" style={{ height: "20rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              paddingBottom: "1rem",
            }}
          >
            <ReactRoundedImage
              image={profile.image}
              roundedSize="0"
              imageWidth="50"
              imageHeight="50"
            />
            <p style={{ fontWeight: "600" }}>{profile.name}</p>
          </div>
          <p style={{ fontWeight: "bold", fontSize: "16px", color: "#0C66E4" }}>
            {" "}
            You are doing a great job!
          </p>
          <br></br>
          <Rating
            name="rider-rating"
            defaultValue={3.5}
            precision={0.5}
            value={profile.rating}
            sx={{
              '& .MuiRating-icon': {
                fontSize: '2rem',
              },
            }}
            readOnly
          />
          {"4.0"}
          <div className="form-row" style={{ paddingTop: "1rem" }}>
            <div className="form-column-1">
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                {profile.totalRides}
              </p>
              <p style={{ fontSize: "16px" }}>Total trips</p>
            </div>
            <div className="form-column-1">
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                {profile.years}
              </p>
              <p style={{ fontSize: "16px" }}>years</p>
            </div>
            <div className="form-column-1">
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                {profile.reviews}
              </p>
              <p style={{ fontSize: "16px" }}>reviews</p>
            </div>
          </div>
        </div>
      </div>
      {apiError && (
        <Alert severity="error">
          Request to server failed. Please try again
        </Alert>
      )}
    </div>
  );
};

export default DriverAchievements;