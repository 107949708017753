import { TextField } from "@mui/material";
import { useState } from "react";

const DriverTripInfo = ({ preferences, handlePreferences }) => {
  const [nullErrors, setNullErrors]=useState({})
  const isEmpty = (field, value, maxVal)=>{
    if (value.length===0 || (parseInt(value)<0) || (parseInt(value)>maxVal)){
      setNullErrors({
        ...nullErrors,
        [field]: true
      })
    }else{
      setNullErrors({
        ...nullErrors,
        [field]: false
      })
    } 
  }

  return (
    <div className="rider-profile-body">
      <p style={{ fontSize: 24, fontWeight: 600 }}>Trip Information</p>
      <div className="form-row">
        <div className="form-column">
          <p>What is your maximum driving distance for the full trip?</p>
          <p>Maximum number of stops</p>
          <p>Maximum hours available for the trip</p>
        </div>
        <div className="form-column">
          <TextField
            error={nullErrors.maxTripDistance}
            helperText={nullErrors.maxTripDistance ? "Provide a valid positive number in miles" : ''}
            className="text-component"
            hiddenLabel
            type="number"
            id="filled-hidden-label-small"
            size="small"
            placeholder="Number in Miles"
            value={preferences.maxTripDistance}
            onChange={(e) => {
              handlePreferences("maxTripDistance", e.target.value);
            }}
            onBlur={(e)=>{
              isEmpty("maxTripDistance",e.target.value, 1000);
            }}
          />
          <TextField
            error={nullErrors.maxNumberOfStops}
            helperText={nullErrors.maxNumberOfStops ? "Provide a valid positive number" : ''}
            type="number"
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="Number"
            value={preferences.maxNumberOfStops}
            onChange={(e) => {
              handlePreferences("maxNumberOfStops", e.target.value);
            }}
            onBlur={(e)=>{
              isEmpty("maxNumberOfStops",e.target.value, 5);

            }}
          />
          <TextField
            error={nullErrors.maxTimeAvailable}
            helperText={nullErrors.maxTimeAvailable ? "Provide a valid positive number in hours" : ''}
            type="number"
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="Number in hours"
            value={preferences.maxTimeAvailable}
            onChange={(e) => {
              handlePreferences("maxTimeAvailable", e.target.value);
            }}
            onBlur={(e)=>{
              isEmpty("maxTimeAvailable",e.target.value, 24);
            }}
            inputProps={{min:1}}
          />
        </div>
      </div>
      {/* <div className="form-row">
        <p>What is your availability?</p>
      </div>
      <div className="form-row">
      <Select
            className="text-component"
            value={availability}
            onChange={handleAvailability}
          >
            <MenuItem value={"Monday"}>Monday</MenuItem>
            <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
            <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
            <MenuItem value={"Thursday"}>Thursday</MenuItem>
            <MenuItem value={"Friday"}>Friday</MenuItem>
            <MenuItem value={"Saturday"}>Saturday</MenuItem>
            <MenuItem value={"Sunday"}>Sunday</MenuItem>
          </Select>
          <TextField
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="From"
          />
          <TextField
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="Till"
          />
      </div> */}
    </div>
  );
};
export default DriverTripInfo;
