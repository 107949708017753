import { useEffect, useState } from "react";
import RiderProfileInfo from "./RiderProfileInfo";
import RiderEmergencyInfo from "./RiderEmergencyInfo";
import RiderPreferences from "./RiderPreferences";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Alert from "@mui/material/Alert";
import _ from "lodash";
import { Button } from "@mui/material";

const RiderProfile = () => {
  const [page, setPage] = useState(0);
  const [nextButton, setNextButton] = useState("Next");
  const [riderID, setRiderID] = useState(0);
  const [user, setUser] = useState({});
  const FormTitles = ["Primary Details", "Emergency Contact Information", "Preferences"];
  const history = useHistory();

  const [imageFile, setImageFile] = useState(null);

  let bearerToken;
  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  const [profile, setProfile] = useState({
    id: 0,
    name: "",
    pronoun: "",
    image: "",
    email: "",
    mobile: "",
    location: "",
    preferredCommunication: "Phone",
  });

  const [emergencyContact, setEmergencyContact] = useState({
    name: "",
    mobile: "",
    homePhone: "",
    email: "",
    preferredCommunication: "Mobile",
  });

  const [covidPreference, setCovidPreference] = useState({
    wearMask: false,
    driverWearMask: false,
  });

  const [preferences, setPreferences] = useState({
    smoke: "No preference",
    gasMoney: "No",
    allergies: "Strong odors or perfumes",
    otherAllergies: "",
    music: "On",
  });

  const [languages, setLanguages] = useState({
    arabic: false,
    english: false,
    spanish: false,
    other: ""
  });

  useEffect(() => {
    const getUserInfo = async () => {
      let id = window.sessionStorage.getItem("userID");
      console.log(id);
      let eID = window.sessionStorage.getItem("eID");
      let memID = window.sessionStorage.getItem("memID");
      setRiderID(id);

      await axios
        .get("https://iuhw-app.hourworld.org/user/" + memID + "?eID=" + eID, { headers: { "Authorization": "Bearer " + bearerToken } })
        .then((response) => {
          setUser({ ...response.data });
        })
        .catch((e) => {
          console.log(e);
        });
    };
    getUserInfo();
  }, []);


  function handleProfile(field, value) {
    setProfile({
      ...profile,
      [field]: value,
    });
  }

  function handleEmergencyContact(field, value) {
    setEmergencyContact({
      ...emergencyContact,
      [field]: value,
    });
  }

  function handleLanguages(field, value) {
    setLanguages({
      ...languages,
      [field]: value,
    });
  }

  function handleCovidPreference(field, value) {
    setCovidPreference({
      ...covidPreference,
      [field]: value,
    });
  }

  function handlePreferences(field, value) {
    setPreferences({
      ...preferences,
      [field]: value,
    });
  }

  function handleImageInput(value) {
    setImageFile(value);
  }

  const [profileInfoAlert, setProfileInfoAlert] = useState(false);
  const [emergencyContactAlert, setEmergencyContactAlert] = useState(false);

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <div>
          <RiderProfileInfo
            profile={profile}
            handleProfile={handleProfile}
            imageFile={imageFile}
            handleImageInput={handleImageInput}
            languages={languages}
            handleLanguages={handleLanguages}
          />
          <br></br>
          {profileInfoAlert && (
            <Alert severity="error">{"Please provide a valid phone number"}</Alert>
          )}
        </div>
      );
    } else if (page === 1) {
      return (
        <div>
          <RiderEmergencyInfo
            emergencyContact={emergencyContact}
            handleEmergencyContact={handleEmergencyContact}
          />
          <br></br>
          {emergencyContactAlert && (
            <Alert severity="error">{"Please provide valid values for the information provided"}</Alert>
          )}
        </div>
      );
    } else {
      return (
        <RiderPreferences
          covidPreference={covidPreference}
          handleCovidPreference={handleCovidPreference}
          preferences={preferences}
          handlePreferences={handlePreferences}
        />
      );
    }
  };

  const handleBack = () => {
    setPage((currPage) => currPage - 1);

    if (page === 2) {
      setNextButton("Next");
    }
  };

  const manageRider = async () => {
    preferences.covid = covidPreference;
    preferences.languages = languages;
    profile.id = riderID;
    profile.name = user.name;
    profile.email = user.emailID;
    profile.location = user.location;

    if (preferences.allergies === "Other") {
      preferences.allergies = preferences.otherAllergies;
      // delete preferences.otherAllergies;
    }

    const riderProfile = {
      profile: profile,
      emergencyContacts: [emergencyContact],
      preferences: preferences,
    };

    await axios
      .post("https://iuhw-app.hourworld.org/rider", _.omit(riderProfile, ["otherAllergies"]))
      .then((response) => {
        console.log(response);
        window.sessionStorage.setItem("riderID", response.data.id);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        return;
      });

    const imageFormData = new FormData();
    imageFormData.append("image", imageFile);

    await axios
      .post(
        "https://iuhw-app.hourworld.org/rider/" +
        riderID +
        "/upload-image",
        imageFormData
      )
      .then(() => {
        history.push("/rider-my-trips");
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
      });
    
    history.push("/rider-my-trips");
  };

  const handleNext = () => {
    if (page === 0) {
      if (
        profile.mobile === "" || !/^[0-9]{10}$/.test(profile.mobile)
      ) {
        setProfileInfoAlert(true);
        return;
      } else {
        setProfileInfoAlert(false);
      }
    }
    else
      if (page === 1) {
        if (
          (emergencyContact.email!=="" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emergencyContact.email)) ||
          (emergencyContact.mobile!=="" && !/^[0-9]{10}$/.test(emergencyContact.mobile)) ||
          (emergencyContact.homePhone!=="" && !/^[0-9]{10}$/.test(emergencyContact.homePhone))
        ) {
          setEmergencyContactAlert(true);
          return;
        } else {
          setEmergencyContactAlert(false);
        }
      }

    if (page>1){
      setPage(1);
    }

    setPage((currPage) => currPage + 1);

    if (page === 1) {
      setNextButton("Submit");
    }

    if (page === 2) {
      manageRider();
    }
  };

  return (
    <div className="profile">
      <div className="body">
        <p className="heading" style={{ fontWeight: "bold" }}>
          Profile Setup
        </p>
        <br />
        <p className="subHeading">
          Let's setup your profile to help drivers during the trip
        </p>
      </div>

      <div className="profile-home">
        <div className="progressbar">
          <div
            style={{
              width: page === 0 ? "36%" : page === 1 ? "66%" : "100%",
            }}
          ></div>
        </div>
        <div className="inline-text">
          <div
            style={{
              color: page === 0 ? "#3579F6" : "black",
              marginLeft: "0%",
            }}
          >
            {FormTitles[0]}
          </div>
          <div
            style={{
              color: page === 1 ? "#3579F6" : "black",
              marginLeft: "12%",
            }}
          >
            {FormTitles[1]}
          </div>
          <div
            style={{
              color: page === 2 ? "#3579F6" : "black",
              marginLeft: "12%",
            }}
          >
            {FormTitles[2]}
          </div>
        </div>
      </div>
      <div className="form-container">
        <div className="form-body">{PageDisplay()}</div>
        <div className="form-footer">
          {page>0 && (<Button 
            variant="contained"
            disabled={page === 0}
            onClick={handleBack}
            style={{
              color: "black",
              background: "#091E4206",
              textTransform: "none",
            }}
          >
            Back
          </Button>)}
          {page<=0 && <div></div>}
          <Button
            variant="contained"
            onClick={handleNext}
            style={{
              color: "white",
              background: "#0C66E4",
              textTransform: "none",
            }}
          >
            {nextButton}
          </Button>
        </div>
      </div>
      <br></br><br></br>
      <p>Please review our privacy policy <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/Privacy.pdf">here</a> and terms of service <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/TOS.pdf">here</a></p><br></br>
      <p><i>This ride service is part of National Science Foundation funded study and is approved by the University of Michigan institutional review board # HUM00166267</i></p>
    </div>
  );
};

export default RiderProfile;
