import { Button, Checkbox, FormControlLabel, MenuItem, Rating, Select, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactRoundedImage from "react-rounded-image";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import HandshakeIcon from '@mui/icons-material/Handshake';

const RiderFeedback = () => {
    const [comments, setComments] = useState("");
    const [rating, setRating] = useState(0);
    const history = useHistory();
    const [apiError, setAPIError] = useState(false);
    const { id } = useParams();

    const [emotion, setEmotion] = useState("");

    const [profile, setProfile] = useState({});
    const [driverProfile, setDriverProfile] = useState({});

    const riderID = window.sessionStorage.getItem("riderID");

    const [tripDetails, setTripDetails] = useState({});
    const queryParams = new URLSearchParams(window.location.search);

    const driverID = queryParams.get('driverID');

    let bearerToken;

    try {
        bearerToken = window.sessionStorage.getItem("bearerToken");
        if (bearerToken === null) {
            history.push("/login");
        };
    } catch (e) {
        history.push("/login");
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

    useEffect(() => {
        const getTripDetails = async () => {
            await axios
                .get("https://iuhw-app.hourworld.org/trip-details/" + id + "?driverID=" + driverID)
                .then((response) => {
                    setTripDetails({
                        ...response.data,
                    });
                    setAPIError(false);
                })
                .catch((e) => {
                    //TODO: Add Alert
                    console.log(e);
                    setAPIError(true);
                });
        };
        getTripDetails();
    }, []);

    useEffect(() => {
        const getRiderProfile = async () => {
            await axios
                .get("https://iuhw-app.hourworld.org/rider/" + riderID)
                .then((response) => {
                    setProfile({
                        ...response.data.profile,
                    });
                    setAPIError(false);
                })
                .catch((e) => {
                    //TODO: Add Alert
                    console.log(e);
                    setAPIError(true);
                });
        };
        getRiderProfile();
    }, []);

    useEffect(() => {
        const getDriverProfile = async () => {
            await axios
                .get("https://iuhw-app.hourworld.org/driver/" + driverID)
                .then((response) => {
                    setDriverProfile({
                        ...response.data.profile,
                    });
                    setAPIError(false);
                })
                .catch((e) => {
                    //TODO: Add Alert
                    console.log(e);
                    setAPIError(true);
                });
        };
        getDriverProfile();
    }, []);

    const handleSubmit = async () => {
        if (driverID === null) {
            return;
        }

        const body = {
            name: profile.name,
            rating: rating,
            review: comments,
            emotion: emotion,
            tripID: id,
            driverID: driverID,
            riderID: riderID,
        };

        if (favorite === true) {
            handleFavourites();
        }

        await axios
            .post("https://iuhw-app.hourworld.org/driver-review", body)
            .then(() => {
                history.push("/home");
                setAPIError(false);
            })
            .catch((e) => {
                console.log(e);
                setAPIError(true);
            });
    };

    const [favorite, setFavorite] = useState(false);

    const handleFavourites = async () => {
        if (driverID === null) {
            return;
        }

        const body = {
            driverID: driverID,
            riderID: riderID,
        };

        await axios
            .post("https://iuhw-app.hourworld.org/rider/" + riderID + "/favourites", body)
            .then(() => {
                setAPIError(false);
                // setPopup(true);
            })
            .catch((e) => {
                console.log(e);
                setAPIError(true);
            });
    };

    return (
        <div className="profile">
            <div className="body">
                <p style={{ fontSize: 20, fontWeight: 600, paddingBottom: "2rem" }}>
                    Add a public review
                </p>
            </div>
            <div className="driver-info-box">
                <div className="rider-profile-body">
                    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                        <ReactRoundedImage
                            image={driverProfile.image}
                            roundedSize="0"
                            imageWidth="50"
                            imageHeight="50"
                        />
                        <p style={{ fontWeight: "600" }}>{driverProfile.name}</p>
                    </div>
                    <Rating
                        name="rider-rating"
                        value={rating}
                        precision={0.5}
                        sx={{
                            '& .MuiRating-icon': {
                                fontSize: '2rem',
                            },
                        }}
                        onChange={(e) => {
                            setRating(e.target.value);
                        }}
                    />{" "}
                    <p style={{ fontWeight: "600" }}>
                        Provide us your public feedback of {driverProfile.name}
                    </p>
                    <TextField
                        className="text-component"
                        hiddenLabel
                        id="filled-hidden-label-small"
                        size="small"
                        placeholder="Comments"
                        value={comments}
                        onChange={(e) => {
                            setComments(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div style={{ paddingTop: "3rem" }}>
                <p style={{ fontSize: 20, fontWeight: 600, paddingBottom: "20px" }}>
                    Send Your Appreciation to the Driver
                </p>
                <div style={{ display: "flex", flexDirection: "row", gap: "5%" }}>
                    <FormControlLabel
                        key="Good"
                        control={<Checkbox
                            checked={emotion === "Good"}
                            icon={<ThumbUpIcon fontSize="large" />}
                            checkedIcon={<ThumbUpIcon fontSize="large" />} />}
                        onChange={() => {
                            setEmotion("Good");
                        }}
                    />
                    <FormControlLabel
                        key="Handshake"
                        control={<Checkbox
                            checked={emotion === "Handshake"}
                            icon={<HandshakeIcon fontSize="large" />}
                            checkedIcon={<HandshakeIcon fontSize="large" />} />}
                        onChange={() => {
                            setEmotion("Handshake");
                        }}
                    />
                    <FormControlLabel
                        key="Satisfied"
                        control={<Checkbox
                            checked={emotion === "Satisfied"}
                            icon={<SentimentSatisfiedAltIcon fontSize="large" />}
                            checkedIcon={<SentimentSatisfiedAltIcon fontSize="large" />} />}
                        onChange={() => {
                            setEmotion("Satisfied");
                        }}
                    />
                </div>
            </div>
            <div style={{ paddingTop: "2rem", display: "flex", direction: "row", gap: "50px" }}>
                <p style={{ fontSize: 20, fontWeight: 600 }}>
                    Add the driver to your list of favorites?
                </p>
                <Select
                    className="text-component"
                    value={favorite}
                    onChange={(e) => {
                        setFavorite(e.target.value)
                    }}
                    style={{ height: 30 }}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
            </div>
            <div style={{ paddingTop: "2rem" }}>
                <p style={{ fontSize: 20, fontWeight: 600, paddingBottom: "20px" }}>
                    Report Safety Concerns to the Timebank
                </p>
                <p>Please email your concerns to the timebank at <a href="mailto:uoc.timebank@gmail.com" target="_blank" rel="noopener noreferrer">uoc.timebank@gmail.com</a></p>
            </div>
            <div style={{ paddingTop: "3rem", display: "flex", direction: "center", gap: "20%" }}>
                <Button
                    variant="contained"
                    onClick={() => { history.push("/rider-my-trips") }}
                    style={{
                        color: "black",
                        background: "#091E4206",
                        textTransform: "none",
                    }}
                >
                    Skip review
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    style={{ color: "white", background: "#0C66E4", textTransform: "none" }}
                >
                    Submit feedback
                </Button>
            </div>
            {apiError && (
                <Alert severity="error">
                    Request to server failed. Please try again
                </Alert>
            )}
        </div>
    );
};

export default RiderFeedback;
