import { useState } from "react";
import { useEffect } from "react";
import { Alert, Button, Checkbox, FormControlLabel, MenuItem, Select } from "@mui/material";
import ReactRoundedImage from "react-rounded-image";
import { useHistory } from "react-router-dom";
import Popup from "./Popup";
import axios from "axios";
import FavoriteIcon from '@mui/icons-material/Favorite';
const DriverTripRequests = () => {
  const history = useHistory();
  const [acceptPopup, setAcceptPopup] = useState([]);
  const [offerPopup, setOfferPopup] = useState([]);
  const [orgPopup, setOrgPopup] = useState([]);
  const [vehiclePopup, setVehiclePopup] = useState(false);
  const [myVehicle, setMyVehicle] = useState("Yes");
  const [buddyMatchup, setBuddyMatchup] = useState("No");
  const driverID = window.sessionStorage.getItem("driverID");
  const [selfError, setSelfError] = useState(false);
  const [apiError, setAPIError] = useState(false);

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  const openPopup = (index) => {
    const updated = [...acceptPopup];
    updated[index] = true;
    setAcceptPopup(updated);
  };

  const closePopup = (index) => {
    const updated = [...acceptPopup];
    updated[index] = false;
    setAcceptPopup(updated);
  };

  const openOrgPopup = (index) => {
    const updated = [...orgPopup];
    updated[index] = true;
    setOrgPopup(updated);
  };

  const closeOrgPopup = (index) => {
    const updated = [...orgPopup];
    updated[index] = false;
    setOrgPopup(updated);
  };

  const openOfferPopup = (index) => {
    const updated = [...offerPopup];
    updated[index] = true;
    setOfferPopup(updated);
  };

  const closeOfferPopup = (index) => {
    const updated = [...offerPopup];
    updated[index] = false;
    setOfferPopup(updated);
  };

  const [tripRequests, setTripRequests] = useState([]);

  const handleAcceptTrip = async (id, riderID, type) => {
    if (riderID === null) {
      return;
    }

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 4);

    if (type === "put") {
      await axios
        .put("https://iuhw-app.hourworld.org/trip-details/" + id, {
          id: id,
          status: "Accepted",
          buddyMatchup: buddyMatchup,
          driverID: driverID,
        })
        .then(() => {
          console.log("Accepted");
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
        });
    } else if (type === "post") {
      await axios
        .post("https://iuhw-app.hourworld.org/trip-details", {
          id: id,
          status: "Accepted",
          buddyMatchup: buddyMatchup,
          driverID: driverID,
        })
        .then(() => {
          console.log("Accepted");
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
        });
    }

    await axios
      .put("https://iuhw-app.hourworld.org/trip-request/" + id, {
        status: "Accepted",
      })
      .then(() => {
        console.log("Accepted");
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
      });

    const driverChecklist = [
      {
        key: "Check gas in vehicle",
        value: false,
      },
      {
        key: "Check tread on tires",
        value: false,
      },
      {
        key: "Check headlights and taillights",
        value: false,
      },
      {
        key: "Check window wiper fluid levels",
        value: false,
      },
      {
        key: "Agree on gas money",
        value: false,
      }
    ]

    await axios
      .get(`https://iuhw-app.hourworld.org/driver/${driverID}`)
      .then((response) => {
        if (response.data.preferences.covid.wearMask === true) {
          driverChecklist.push({
            key: "Wear a face mask",
            value: false
          })
        }
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });

    let data;

    await axios
      .get(
        `https://iuhw-app.hourworld.org/trip-request/${id}/checklist`
      )
      .then((response) => {
        data = response.data;
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });

    data.driver_checklist = driverChecklist;
    await axios
      .put(
        `https://iuhw-app.hourworld.org/trip-request/${id}/checklist`,
        data,
      )
      .then((response) => {
        history.push(`/trip/${id}/driver-checklist?riderID=${riderID}`);
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });
  };


  const handleOfferRide = async (id, riderID) => {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 4);
    await axios
      .post("https://iuhw-app.hourworld.org/trip-details", {
        id: id,
        status: "Offered",
        buddyMatchup: buddyMatchup,
        driverID: driverID,
      })
      .then(() => {
        console.log("Offered");
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
      });

    await axios
      .put("https://iuhw-app.hourworld.org/trip-request/" + id, {
        status: "Requested_Rider",
      })
      .then(() => {
        history.push(`/driver-my-trips`);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
      });
  };

  const handleDismiss = () => {
    history.push("/");
  };

  useEffect(() => {
    const fetchTripRequests = async () => {
      try {
        let trips = [];
        const response = await axios
          .get("https://iuhw-app.hourworld.org/trip-requests")
          .then((response) => {
            trips = response.data;
          });
        // const trips = response.data || [];
        setTripRequests(trips);
        const initial = trips.map(() => false);
        setAcceptPopup(initial);
        setOfferPopup(initial);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // if (tripRequests.length === 0) {
    fetchTripRequests();
    // }
    // }, [tripRequests]);
  }, []);

  return (
    <div className="profile">
      <div className="body" style={{ paddingBottom: "3rem" }}>
        <p className="heading" style={{ fontWeight: "bold" }}>
          Trip Requests Near You
        </p>
      </div>
      {selfError && (
        <Alert severity="error">
          You cannot accept your  request. Please wait for others to accept.
        </Alert>
      )}
      <div>
        {tripRequests.map((trip, index) => {

          let covid = "";
          if (trip.covid.wearMask === true) {
            covid += "I will wear a mask, ";
          }
          if (trip.covid.driverWearMask === true) {
            covid += "I want the driver to wear a mask, ";
          }

          if (covid !== "") {
            covid = covid.slice(0, -2);
          } else {
            covid = "None"
          }

          let gasMoney = "";

          if (trip.gas_money === "Yes") {
            gasMoney = "Gas money can be provided.";
          } else {
            gasMoney = "Gas money will not be provided.";
          }

          let languages = "";
          let langs = {};
          if (trip.self_booking === "Yes") {
            langs = trip.languages;
          } else {
            langs = JSON.parse(trip.rider_details).languages;
          }

          if (langs.english === true) {
            languages += "English, ";
          }
          if (langs.arabic === true) {
            languages += "Arabic, ";
          }
          if (langs.spanish === true) {
            languages += "Spanish, ";
          }

          if (langs.other === "") {
            languages = languages.slice(0, -2);
          } else {
            languages += langs.other
          }
          return (
            <div key={trip.id} >
              <div className="driver-box">
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "70%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <ReactRoundedImage
                        image={trip.image}
                        roundedSize="0"
                        imageWidth="60"
                        imageHeight="60"
                      />
                      <p style={{ fontWeight: "600" }}>{trip.name} </p>
                      {trip.favourite === true && <FavoriteIcon />}
                    </div>
                    {(trip.status.toLowerCase() !== "requested_driver" && trip.rider_details === null) && (<Button
                      variant="contained"
                      onClick={() => {
                        setSelfError(false);
                        if (trip.rider_id.toString() === driverID) {
                          setSelfError(true);
                          return;
                        }
                        openOfferPopup(index);
                      }
                      }
                      style={{
                        color: "white",
                        background: "#0C66E4",
                        textTransform: "none",
                      }}
                    >
                      Offer Ride
                    </Button>)}
                    <Popup key={trip.id} openPopup={offerPopup[index]}>
                      <div>
                        <p className="heading">Requested trip accomodations</p>
                        <br></br>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <ReactRoundedImage
                            image={trip.image}
                            roundedSize="0"
                            imageWidth="60"
                            imageHeight="60"
                          />
                          <p style={{ fontWeight: "600" }}>
                            Hi {trip.name}! Thanks for offering a ride for my trip!
                          </p>
                        </div>
                        <br></br>
                        <div className="driver-box">
                          <b>Wait during the appointment</b>
                          <p>Estimated time: {trip.wait_time}</p>
                        </div>
                        <br></br>
                        <div className="driver-box">
                          <b>Total stops</b>
                          <p>{trip.number_of_stops}</p>
                        </div>
                        <br></br>
                        {(trip.self_booking === "Yes") && (<div className="driver-box">
                          <p>{gasMoney}</p>
                          <br></br>
                        </div>)}
                        <FormControlLabel
                          control={<Checkbox />}
                          label="I would like to be matched up with a timebank buddy"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setBuddyMatchup("Yes")
                            } else {
                              setBuddyMatchup("No")
                            }
                          }}
                        />
                        <p><i>(A timebank buddy is someone who can check in with you during your ride.
                          Your timebank buddy matchup request will be posted on hOurworld along with your trip request)</i></p><br></br>
                        {/* <div className="driver-box">
                        <b>Stop Point: </b>
                        <p>{trip.stop_point}</p>
                      </div>
                      <br></br> */}
                        <div className="form-row">
                          <p><br></br>I will be using my vehicle listed on my profile</p>
                          <Select
                            className="text-component"
                            value={myVehicle}
                            onChange={(e) => {
                              setMyVehicle(e.target.value);
                              if (e.target.value === "No") {
                                setVehiclePopup(true);
                              } else {
                                setVehiclePopup(false);
                              }
                            }}
                            style={{ height: 40 }}
                          >
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </div>
                        <Popup
                          openPopup={vehiclePopup}
                        >
                          <div>
                            Please update your driver profile before you make a ride offer
                            <div
                              style={{
                                paddingTop: "2rem",
                                display: "flex",
                                justifyContent: "center"
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() => {
                                  history.push("/edit-driver-profile");
                                  setVehiclePopup(false);
                                }}
                                style={{
                                  color: "black",
                                  background: "#091E4206",
                                  textTransform: "none",
                                }}
                              >
                                Okay
                              </Button>
                            </div>
                          </div>
                        </Popup>
                        <br></br>
                        <div
                          style={{
                            paddingTop: "3rem",
                            display: "flex",
                            flexDirection: "row",
                            gap: "50%",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              closeOfferPopup(index);
                            }}
                            style={{
                              color: "black",
                              background: "#091E4206",
                              textTransform: "none",
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              color: "white",
                              background: "#0C66E4",
                              textTransform: "none",
                            }}
                            onClick={() => {
                              handleOfferRide(trip.id, trip.rider_id);
                            }}
                          >
                            Offer Ride
                          </Button>
                        </div>
                      </div>
                    </Popup>
                    {(trip.status.toLowerCase() === "requested_driver" || trip.rider_details === null) && (<Button
                      variant="contained"
                      onClick={() => {
                        setSelfError(false);
                        if (trip.rider_id.toString() === driverID) {
                          setSelfError(true);
                          return;
                        }
                        openPopup(index);
                      }
                      }
                      style={{
                        color: "white",
                        background: "#0C66E4",
                        textTransform: "none",
                      }}
                    >
                      Accept Request
                    </Button>)}
                    {(trip.rider_details !== null) && (<Button
                      variant="contained"
                      onClick={() => {
                        setSelfError(false);
                        if (trip.rider_id.toString() === driverID) {
                          setSelfError(true);
                          return;
                        }
                        openOrgPopup(index);
                      }
                      }
                      style={{
                        color: "white",
                        background: "#0C66E4",
                        textTransform: "none",
                      }}
                    >
                      Offer Ride
                    </Button>)}
                    <Popup key={trip.id} openPopup={orgPopup[index]}>
                      <div>
                        <p className="heading">Requested trip accomodations</p>
                        <br></br>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <ReactRoundedImage
                            image={trip.image}
                            roundedSize="0"
                            imageWidth="60"
                            imageHeight="60"
                          />
                          <p style={{ fontWeight: "600" }}>
                            Hi {trip.name}! Thanks for accepting my request!
                          </p>
                        </div>
                        <br></br>
                        <div className="driver-box">
                          <b>Wait during the appointment</b>
                          <p>Estimated time: {trip.wait_time}</p>
                        </div>
                        <br></br>
                        <div className="driver-box">
                          <b>Total stops</b>
                          <p>{trip.number_of_stops}</p>
                        </div>
                        <br></br>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="I would like to be matched up with a timebank buddy"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setBuddyMatchup("Yes")
                            } else {
                              setBuddyMatchup("No")
                            }
                          }}
                        />
                        <p><i>(A timebank buddy is someone who can check in with you during your ride.
                          Your timebank buddy matchup request will be posted on hOurworld along with your trip request)</i></p><br></br>
                        <div className="form-row">
                          <p><br></br>I will be using my vehicle listed on my profile</p>
                          <Select
                            className="text-component"
                            value={myVehicle}
                            onChange={(e) => {
                              setMyVehicle(e.target.value);
                              if (e.target.value === "No") {
                                setVehiclePopup(true);
                              } else {
                                setVehiclePopup(false);
                              }
                            }}
                            style={{ height: 40 }}
                          >
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </div>
                        <Popup
                          openPopup={vehiclePopup}
                        >
                          <div>
                            Please update your driver profile before you make a ride offer
                            <div
                              style={{
                                paddingTop: "2rem",
                                display: "flex",
                                justifyContent: "center"
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() => {
                                  history.push("/edit-driver-profile")
                                  setVehiclePopup(false);
                                }}
                                style={{
                                  color: "black",
                                  background: "#091E4206",
                                  textTransform: "none",
                                }}
                              >
                                Okay
                              </Button>
                            </div>
                          </div>
                        </Popup>
                        <br></br>
                        <div
                          style={{
                            paddingTop: "3rem",
                            display: "flex",
                            flexDirection: "row",
                            gap: "50%",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              closeOrgPopup(index);
                            }}
                            style={{
                              color: "black",
                              background: "#091E4206",
                              textTransform: "none",
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              color: "white",
                              background: "#0C66E4",
                              textTransform: "none",
                            }}
                            onClick={() => {
                              handleAcceptTrip(trip.id, trip.rider_id, "post");
                            }}
                          >
                            Offer Ride
                          </Button>
                        </div>
                      </div>
                    </Popup>
                  </div>
                  <Popup key={trip.id} openPopup={acceptPopup[index]}>
                    <div>
                      <p className="heading">Requested trip accomodations</p>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <ReactRoundedImage
                          image={trip.image}
                          roundedSize="0"
                          imageWidth="60"
                          imageHeight="60"
                        />
                        <p style={{ fontWeight: "600" }}>
                          Hi {trip.name}! Thanks for accepting my request!
                        </p>
                      </div>
                      <br></br>
                      <div className="driver-box">
                        <b>Wait during the appointment</b>
                        <p>Estimated time: {trip.wait_time}</p>
                      </div>
                      <br></br>
                      <div className="driver-box">
                        <b>Total stops</b>
                        <p>{trip.number_of_stops}</p>
                      </div>
                      <br></br>
                      {(trip.self_booking === "Yes") && (<div className="driver-box">
                        <p>{gasMoney}</p>
                        <br></br>
                      </div>
                      )}
                      <FormControlLabel
                        control={<Checkbox />}
                        label="I would like to be matched up with a timebank buddy"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setBuddyMatchup("Yes")
                          } else {
                            setBuddyMatchup("No")
                          }
                        }}
                      />
                      <p><i>(A timebank buddy is someone who can check in with you during your ride.
                        Your timebank buddy matchup request will be posted on hOurworld along with your trip request)</i></p><br></br>
                      {/* <div className="driver-box">
                        <b>Stop Point: </b>
                        <p>{trip.stop_point}</p>
                      </div>
                      <br></br> */}
                      <div className="form-row">
                        <p><br></br>I will be using my vehicle listed on my profile</p>
                        <Select
                          className="text-component"
                          value={myVehicle}
                          onChange={(e) => {
                            setMyVehicle(e.target.value);
                            if (e.target.value === "No") {
                              setVehiclePopup(true);
                            } else {
                              setVehiclePopup(false);
                            }
                          }}
                          style={{ height: 40 }}
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      </div>
                      <Popup
                        openPopup={vehiclePopup}
                      >
                        <div>
                          Please update your driver profile before you make a ride offer
                          <div
                            style={{
                              paddingTop: "2rem",
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                history.push("/edit-driver-profile")
                                setVehiclePopup(false);
                              }}
                              style={{
                                color: "black",
                                background: "#091E4206",
                                textTransform: "none",
                              }}
                            >
                              Okay
                            </Button>
                          </div>
                        </div>
                      </Popup>
                      <br></br>
                      <div
                        style={{
                          paddingTop: "3rem",
                          display: "flex",
                          flexDirection: "row",
                          gap: "50%",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            closePopup(index);
                          }}
                          style={{
                            color: "black",
                            background: "#091E4206",
                            textTransform: "none",
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            background: "#0C66E4",
                            textTransform: "none",
                          }}
                          onClick={() => {
                            handleAcceptTrip(trip.id, trip.rider_id, "put");
                          }}
                        >
                          Accept Request
                        </Button>
                      </div>
                    </div>
                  </Popup>
                  <br></br>
                  <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                    {trip.trip}
                  </p>
                  <br></br>
                  <br></br>
                  <p><i></i></p>
                  <div className="form-row">
                    <div className="form-column-1">
                      {/* <p>
                        Pickup point:
                        <b> {trip.pickup}</b>
                      </p>
                      <br></br>
                      <p>
                        Destination:
                        <b> {trip.destination}</b>
                      </p>
                      <br></br> */}
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Nearby Pickup Intersection
                      </p>
                      <br></br>
                      <p>{trip.nearby_intersection}</p>
                    </div>
                    <div className="form-column-1">
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Trip date
                      </p>
                      <br></br>
                      <p>{trip.date}</p>
                    </div>
                    <div className="form-column-1">
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Pickup Time
                      </p>
                      <br></br>
                      <p>{trip.time}</p>
                    </div>
                    {/* <div className="form-column-1">
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Trip Time
                      </p>
                      <br></br>
                      <p>{trip.trip_time} hr</p>
                    </div> */}
                    <br></br>
                  </div>
                  <br></br>
                  {/* <p>
                      End time:
                      <b> {trip.tripEndTime}</b>
                    </p>
                    <br></br> */}
                  <br></br>
                  <div className="form-row">
                    <div className="form-column-1">
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Spoken Languages
                      </p>
                      <br></br>
                      <p>{languages}</p>
                      <br></br>
                    </div>
                    <div className="form-column-1">
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Additional Stops
                      </p>
                      <br></br>
                      <p>{trip.number_of_stops}</p>
                      <br></br>
                    </div>
                    <div className="form-column-1">
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Number of passengers
                      </p>
                      <br></br>
                      <p>{parseInt(trip.number_of_passengers) + 1}</p>
                      <br></br>
                    </div>
                  </div>
                  <br></br>
                  {trip.self_booking === "Yes" && (<div>
                    <div className="form-row">
                      <div className="form-column-1">
                        <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                          Health-related safety preferences
                        </p>
                        <br></br>
                        <p>{covid}</p>
                        <br></br>
                      </div>
                      <div className="form-column-1">
                        <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                          Allergies
                        </p>
                        <br></br>
                        <p>
                          {trip.allergies}
                        </p>
                        <br></br>
                      </div>
                    </div>
                    <br></br>
                    <div className="form-row">
                      <div className="form-column-1">
                        <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                          Smoking preferences
                        </p>
                        <br></br>
                        <p>{trip.smoke}</p>
                        <br></br>
                      </div>
                      <div className="form-column-1">
                        <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                          Gas Money
                        </p>
                        <br></br>
                        <p>{gasMoney}</p>
                        <br></br>
                      </div>
                    </div>
                    <br></br>
                  </div>)}
                </div>
              </div>
              <br></br>
            </div>
          );
        })}
      </div >
    </div >
  );
};

export default DriverTripRequests;
