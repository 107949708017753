import {useHistory} from "react-router-dom";

const Card = ({image,title,description,submit,route}) => {
    const history = useHistory();
    const handleSubmit = () => {
        history.push(route);
    }

    return (  
        <div className="card">
            <img src={image} alt="" className="cardImage"/>
            <div className="cardBody">
                <h2 className="cardTitle">
                    {title}
                </h2>
                <p className="cardDescription">
                    {description}
                </p>
                <button className="cardButton" onClick={handleSubmit}>
                    {submit}
                </button>
            </div>
        </div>
    );
}

 
export default Card;