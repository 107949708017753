import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";

const RiderEmergencyInfo = ({ emergencyContact, handleEmergencyContact }) => {
  const [nullErrors, setNullErrors]=useState({})

  const isEmpty = (field, value)=>{
    if (value.length===0){
      setNullErrors({
        ...nullErrors,
        [field]: true
      })
    }else{
      setNullErrors({
        ...nullErrors,
        [field]: false
      })
    } 
  }

  const [emailError, setEmailError] = useState(null);

  const checkEmail = (value) => {
    if (value!=="" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const [contactErrors, setContactErrors] = useState({
    mobile: false,
    home: false,
  });

  const checkContact = (field, value) => {
    if (value!=="" && !/^[0-9]{10}$/.test(value)) {
      setContactErrors({
        ...contactErrors,
        [field]: true
      });
    } else {
      setContactErrors({
        ...contactErrors,
        [field]: false
      });
    }
  };

  return (
    <div className="rider-profile-body">
      <p style={{ fontSize: 24, fontWeight: 600 }}>Emergency Contact Information</p>
      <p><i>(The provided emergency contact information will be shared with the driver)</i></p>
      <div className="form-row">
        <div className="form-column">
          <p>Emergency contact name:</p>
          <p>Emergency contact cell number:</p>
          <p>Emergency contact home phone number:</p>
          <p>Emergency contact email addres:</p>
          <p>Preferred method of communication</p>
        </div>
        <div className="form-column">
          <TextField
            // error={nullErrors.name}
            // helperText={nullErrors.name ? "Name cannot be empty" : ''}
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={emergencyContact.name}
            placeholder="Enter Full Name"
            onChange={(e) => {
              handleEmergencyContact("name", e.target.value);
            }}
            onBlur={(e)=>{
              isEmpty("name",e.target.value);
            }}
          />
          <TextField
            error={contactErrors.mobile}
            helperText={contactErrors.mobile ? "Please enter a valid 10 digit phone number without any extra characters" : ''}
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={emergencyContact.mobile}
            placeholder="1234567890"
            onChange={(e) => {
              handleEmergencyContact("mobile", e.target.value);
            }}
            onBlur={(e)=>{
              checkContact("mobile", e.target.value);
            }}
          />
          <TextField
            error={contactErrors.home}
            helperText={contactErrors.home ? "Please enter a valid 10 digit phone number without any extra characters" : ''}
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={emergencyContact.homePhone}
            placeholder="9876543210"
            onChange={(e) => {
              handleEmergencyContact("homePhone", e.target.value);
            }}
            onBlur={(e)=>{
              checkContact("home", e.target.value);
            }}
          />
          <TextField
            error={emailError}
            helperText={emailError ? "Please enter a valid email address" : ''}
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={emergencyContact.email}
            placeholder="Email address"
            onChange={(e) => {
              handleEmergencyContact("email", e.target.value);
            }}
            onBlur={(e)=>{
              checkEmail(e.target.value);
            }}
          />
          <Select
            className="text-component"
            value={emergencyContact.preferredCommunication}
            onChange={(e) => {
              handleEmergencyContact("preferredCommunication", e.target.value);
            }}
            style={{ height: 40 }}
          >
            <MenuItem value={"Mobile"}>Mobile</MenuItem>
            <MenuItem value={"Home"}>Home</MenuItem>
            <MenuItem value={"Email"}>Email</MenuItem>
          </Select>
          {/* <input className="text-input" placeholder="Enter Full Name"></input>
                    <input className="text-input" placeholder="Mobile Phone Number"></input>
                    <input className="text-input" placeholder="Home Phone Number"></input>
                    <input className="text-input" placeholder="Mail address"></input> */}
        </div>
      </div>
      {/* <div>
        <Button style={{ textTransform: "none" }}>
          <p style={{ fontWeight: "bold", color: "#3579F6" }}>
            Add another emergency contact
          </p>
        </Button>
      </div> */}
    </div>
  );
};

export default RiderEmergencyInfo;
