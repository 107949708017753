import Button from '@atlaskit/button';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";

const NavBar = () => {
    const history = useHistory();
    const location = useLocation();
    const [riderID, setRiderID] = useState(null);
    const [driverID, setDriverID] = useState(null);
    
    useEffect(()=>{
        setRiderID(window.sessionStorage.getItem("riderID"));
        setDriverID(window.sessionStorage.getItem("driverID"));
    },[])
    
    if (location.pathname === "/login") {
        return <div style={{ padding: "30px", backgroundColor: "#f8f9fa" }}></div>
    }

    const bearerToken = window.sessionStorage.getItem("bearerToken");
    // let bearerToken;

    // try {
    //     bearerToken = window.sessionStorage.getItem("bearerToken");
    //     if (bearerToken === null) {
    //         history.push("/login");
    //     };
    // } catch (e) {
    //     history.push("/login");
    // }
    
    const eID=window.sessionStorage.getItem("eID");

    const handleGoToHome = () => history.push(`/home?eID=${eID}`);

    const handleGoToMyRiderTrips = () => {
        if (riderID!==null || window.sessionStorage.getItem("riderID")!=null){
            history.push("/rider-my-trips")
        }
    };
    const handleGoToMyDriverTrips = () => {
        if (driverID!==null || window.sessionStorage.getItem("driverID")!=null){
            history.push("/driver-my-trips")
        }
    };

    const handleGoToMyRiderProfile = () => {
        if (riderID!==null){
            history.push(`/rider-public-profile?riderID=${riderID}`)
            return;
        }

        const id= window.sessionStorage.getItem("riderID");
        if (id !=null){
            history.push(`/rider-public-profile?riderID=${id}`)
        }
    };
    const handleGoToMyDriverProfile = () => {
        if (driverID!==null){
            history.push(`/driver-public-profile?driverID=${driverID}`)
            return;
        }

        const id= window.sessionStorage.getItem("driverID");
        if (id !=null){
            history.push(`/driver-public-profile?driverID=${id}`)
        }
    };

    const handleLogout = async () => {
        let id = window.sessionStorage.getItem("userID")
        await axios
            .post("https://iuhw-app.hourworld.org/user/" + id + "/logout", null, { headers: { "Authorization": "Bearer " + bearerToken } })
            .then(() => {
                window.sessionStorage.removeItem("bearerToken");
                window.sessionStorage.removeItem("userID");
                window.sessionStorage.removeItem("eID");
                window.sessionStorage.removeItem("memID");
                window.sessionStorage.removeItem("riderID");
                window.sessionStorage.removeItem("driverID");
                history.push(`/login?eID=${eID}`);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    return (
        <nav className="navbar">
            <div className='atlasButton'>
                <Button appearance="subtle" onClick={handleGoToHome}>Home</Button>
                <Button appearance="subtle" onClick={handleGoToMyRiderTrips}>My Ride Requests</Button>
                <Button appearance="subtle" onClick={handleGoToMyDriverTrips}>My Ride Offers</Button>
                <Button appearance="subtle" onClick={handleGoToMyRiderProfile}>My Rider Profile</Button>
                <Button appearance="subtle" onClick={handleGoToMyDriverProfile}>My Driver Profile</Button>
                <Button appearance="subtle" onClick={() => { window.open("https://www.hourworld.org"); }}>hOurworld</Button>
                {/* <Button appearance="subtle">My Profile</Button> */}
                <Button appearance="primary" onClick={handleLogout}>Logout</Button>
            </div>
        </nav>
    );
}

export default NavBar;