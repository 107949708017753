import { Tab, Tabs, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import ReactRoundedImage from "react-rounded-image";
import { useHistory } from "react-router-dom";
import Popup from "./Popup";
import axios from "axios";
import Alert from "@mui/material/Alert";

const DriverMyTrips = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const history = useHistory();
  const [popUp, setPopup] = useState(false);
  const driverID = window.sessionStorage.getItem("driverID");
  const [apiError, setAPIError] = useState(false);
  const [tripCancellationReason, setCancellationReason] = useState("");
  const [tripID, setTripID] = useState(0);
  const [tripStatus, setTripStatus] = useState("");

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  const openPopup = () => {
    setPopup(true);
  };

  const closePopup = () => {
    setPopup(false);
  };

  const viewTripRequests = () => {
    history.push("/driver-trip-requests");
  };

  const handleOffer = () => {
    history.push("/driver-profile-preview");
  };

  const handleCancelTrip = async () => {
    await axios
      .put(
        "https://iuhw-app.hourworld.org/trip-details/" + tripID,
        {
          status: "Cancelled",
          additionalComments: tripCancellationReason,
        }
      )
      .then(() => {
        console.log("Cancelled trip request");
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
      });

    if (tripStatus.toLowerCase() !== "accepted") {
      closePopup();
      return;
    }

    await axios
      .put("https://iuhw-app.hourworld.org/trip-request/" + tripID, {
        status: "Cancelled",
        additionalComments: tripCancellationReason,
      })
      .then(() => {
        console.log("Cancelled trip request");
        closePopup();
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
      });
  };

  const handleViewChecklist = (id, riderID) => {
    if (riderID === null) {
      return null;
    }

    history.push(`/trip/${id}/driver-checklist?riderID=${riderID}`);
  };

  const handleStartTrip = (id, riderID) => {
    if (riderID === null) {
      return null;
    }

    history.push(`/trip/${id}/driver-checklist?riderID=${riderID}&page=1`);
  };

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const [profile, setProfile] = useState({});
  const [vehicleInformation, setVehicleInformation] = useState({});
  const [preferences, setPreferences] = useState({});
  const [upcomingTrips, setUpcomingTrips] = useState([]);
  const [upcomingTripElements, setUpcomingTripElements] = useState([]);
  const [pastTrips, setPastTrips] = useState([]);
  const [pastTripElements, setPastTripElements] = useState([]);
  const [covidPreference, setCovidPreference] = useState("");
  const [languages, setLanguages] = useState("");

  useEffect(() => {
    const getDriverProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/driver/" + driverID)
        .then((response) => {
          setProfile({
            ...response.data.profile,
          });
          setVehicleInformation({
            ...response.data.vehicleInformation
          });
          setPreferences({
            ...response.data.preferences,
          });

          let covid = "";
          if (response.data.preferences.covid.wearMask === true) {
            covid += "I will wear a mask, ";
          }
          if (response.data.preferences.covid.riderWearMask === true) {
            covid += "I want the rider to wear a mask, ";
          }

          if (covid !== "") {
            covid = covid.slice(0, -2);
          } else {
            covid = "None"
          }

          setCovidPreference(covid);

          let languages = "";
          if (response.data.preferences.languages.english === true) {
            languages += "English, ";
          }
          if (response.data.preferences.languages.arabic === true) {
            languages += "Arabic, ";
          }
          if (response.data.preferences.languages.spanish === true) {
            languages += "Spanish, ";
          }

          if (response.data.preferences.languages.other === "") {
            languages = languages.slice(0, -2);
          } else {
            languages += response.data.preferences.languages.other
          }

          setLanguages(languages);

          // window.sessionStorage.setItem(
          //   "driverProfile",
          //   JSON.stringify(response.data)
          // );
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getDriverProfile();
  }, []);

  useEffect(() => {
    setUpcomingTripElements([]);
    const getUpcomingTrips = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/driver/" + driverID + "/trips?type=upcoming")
        .then((response) => {
          setUpcomingTrips(response.data);
          let trips = [];
          for (let i = 0; i < response.data.length; i++) {
            trips.push(
              <div key={response.data[i].id} className="driver-info-box">
                {/* <div style={{ display: "flex", alignItems: "center", gap: "70%" }}>
                  <p>{response.data[i].date}</p>
                  <p>{response.data[i].time}</p>
                </div> */}
                {/* <br></br> */}
                <p style={{ fontWeight: "600" }}>{response.data[i].trip}</p>
                <br></br>
                <p>Date of Appointment: {response.data[i].date}</p>
                <br></br>
                <p>Pickup time: {response.data[i].time}</p>
                <br></br>
                <p>Pickup point: {response.data[i].pickup}</p>
                <br></br>
                <p>Nearby Intersection: {response.data[i].nearby_intersection}</p>
                <br></br>
                <p>Destination: {response.data[i].destination}</p>
                <br></br>
                {response.data[i].timebank_buddy == "Yes" && (<><p>Timebank buddy matchup: Requested</p><br></br></>)}
                {response.data[i].status === "Completed" && (<p>Trip time: {response.data[i].trip_time}<br></br></p>)}
                <br></br>
                {/* <p>Purpose: {response.data[i].purpose}</p> */}
                {/* <br></br> */}
                <div style={{ display: "flex", alignItems: "center", gap: "20%" }}>
                  {response.data[i].self_booking === "Yes" && response.data[i].rider_id !== 0 && response.data[i].status === "Accepted" && <Button
                    variant="contained"
                    style={{
                      color: "white",
                      background: "#0C66E4",
                      textTransform: "none",
                    }}
                    onClick={() => { handleViewChecklist(response.data[i].id, response.data[i].rider_id) }}
                  >
                    View Checklist
                  </Button>}
                  {response.data[i].rider_id !== 0 && response.data[i].status === "Accepted" && <Button
                    variant="contained"
                    style={{
                      color: "white",
                      background: "green",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      handleStartTrip(response.data[i].id, response.data[i].rider_id);
                    }}
                  >
                    Start trip
                  </Button>}
                  <Button
                    onClick={() => {
                      setTripID(response.data[i].id);
                      setTripStatus(response.data[i].status);
                      openPopup();
                    }}
                    variant="contained"
                    style={{
                      textTransform: "none",
                      color: "black",
                      background: "#F8f9fa",
                    }}
                  >
                    Cancel trip
                  </Button>
                </div>
              </div>,
              <br></br>
            );
          }
          setUpcomingTripElements(trips);
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getUpcomingTrips();
  }, []);


  useEffect(() => {
    setPastTripElements([]);
    const getPastTrips = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/driver/" + driverID + "/trips?type=past")
        .then((response) => {
          setPastTrips(response.data);
          let trips = [];
          for (let i = 0; i < response.data.length; i++) {
            trips.push(
              <div key={response.data[i].id} className="driver-info-box">
                {/* <div style={{ display: "flex", alignItems: "center", gap: "70%" }}>
                  <p>{response.data[i].date}</p>
                  <p>{response.data[i].time}</p>
                </div> */}
                {/* <br></br> */}
                <p style={{ fontWeight: "600" }}>{response.data[i].trip}</p>
                <br></br>
                <p>Date of Appointment: {response.data[i].date}</p>
                <br></br>
                <p>Pickup time: {response.data[i].time}</p>
                <br></br>
                <p>Pickup point: {response.data[i].pickup}</p>
                <br></br>
                <p>Nearby Intersection: {response.data[i].nearby_intersection}</p>
                <br></br>
                <p>Destination: {response.data[i].destination}</p>
                <br></br>
                {response.data[i].timebank_buddy == "Yes" && (<><p>Timebank buddy matchup: Requested</p><br></br></>)}
                {response.data[i].status === "Completed" && (<p>Trip time: {response.data[i].trip_time} hours<br></br></p>)}
                <br></br>
                {/* <p>Purpose: {response.data[i].purpose}</p>
                <br></br> */}
                <div style={{ display: "flex", alignItems: "center", gap: "50%" }}>
                  {/* { response.data[i].status !=="Cancelled" && <Button
                  variant="contained"
                  style={{
                    color: "white",
                    background: "#0C66E4",
                    textTransform: "none",
                  }}
                >
                  View Driver
                </Button>} */}
                  {(response.data[i].status.toLowerCase() === "completed" || response.data[i].status.toLowerCase() === "accepted") && (
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        background: "#0C66E4",
                        textTransform: "none",
                      }}
                      onClick={() => { history.push(`/driver-trip/${response.data[i].id}/feedback?riderID=${response.data[i].rider_id}`); }}
                    >
                      Share feedback
                    </Button>)}
                  {(response.data[i].status.toLowerCase() === "completed" || response.data[i].status.toLowerCase() === "accepted") && (
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        background: "#0C66E4",
                        textTransform: "none",
                      }}
                      onClick={() => { history.push(`/trip/${response.data[i].id}/preview-checklist?driver=true`); }}
                    >
                      View Checklist
                    </Button>)}
                </div>

              </div>, <br></br>
            );
          }
          setPastTripElements(trips);
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getPastTrips();
  }, []);

  return (
    <div className="profile">
      <div className="body">
        <p className="heading" style={{ fontWeight: "bold" }}>
          My Trips
        </p>
      </div>

      <div className="split-body">
        <div>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="Upcoming Trips" style={{ textTransform: "None" }}></Tab>
            <Tab label="Trip History" style={{ textTransform: "None" }}></Tab>
          </Tabs>

          {selectedTab === 0 && (
            <div>
              <div className="split-column">
                <p style={{ fontSize: 24, fontWeight: 600 }}>Upcoming Trips</p>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    background: "#0C66E4",
                    textTransform: "none",
                  }}
                  onClick={viewTripRequests}
                >
                  View trip requests
                </Button>
              </div>
              <br></br>
              <div>{upcomingTripElements}</div>
              <Popup openPopup={popUp}>
                <div>
                  <p className="heading"> Do you want to cancel the trip?</p>
                  <br></br>
                  <p>Are you sure you want to cancel the trip?</p>
                  <br></br>
                  <p>State the reason for trip cancellation?</p>
                  <TextField
                    className="text-component"
                    hiddenLabel
                    id="filled-hidden-label-small"
                    size="small"
                    placeholder="Reason"
                    value={tripCancellationReason}
                    onChange={(e) => {
                      setCancellationReason(e.target.value);
                    }}
                  />
                  <div
                    style={{
                      paddingTop: "3rem",
                      display: "flex",
                      flexDirection: "row",
                      gap: "50%",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={closePopup}
                      style={{
                        color: "black",
                        background: "#091E4206",
                        textTransform: "none",
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        background: "#0C66E4",
                        textTransform: "none",
                      }}
                      onClick={handleCancelTrip}
                    >
                      Cancel trip
                    </Button>
                  </div>
                </div>
              </Popup>
              <br />
              <div className="driver-info-box">
                <p style={{ fontWeight: "600" }}>
                  Your upcoming trips will be displayed here
                </p>
                <br></br>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    background: "#0C66E4",
                    textTransform: "none",
                  }}
                  onClick={viewTripRequests}
                >
                  View trip requests
                </Button>
              </div>
            </div>
          )}

          {selectedTab === 1 && (
            <div>
              <div className="split-column">
                <p style={{ fontSize: 24, fontWeight: 600 }}>Trip History</p>
              </div>
              <br></br>
              <div>{pastTripElements}</div>
            </div>
          )}
        </div>
        <div>
          {/* <div style={{ paddingBottom: "3rem" }}>
            <p style={{ fontSize: 24, fontWeight: 600 }}>My trip offer</p>
            <br></br>
            <p>Let others request you for trips.</p>
            <br></br>
            <Button
              onClick={handleOffer}
              variant="contained"
              style={{
                color: "white",
                background: "#0C66E4",
                textTransform: "none",
              }}
            >
              Create an offer
            </Button>
          </div> */}
          {vehicleInformation.certificate_date === null && (<div>
            <p style={{ fontSize: 24, fontWeight: 600 }}>Complete Course</p>
            <br></br>
            <p>
              To start giving trips, you need to take the Unity in Our Community
              free 2-hour driver training couse.
            </p>
            <br></br>
            <Button variant="contained" component="a" href="mailto:uoc.timebank@gmail.com" target="_blank"
              rel="noopener noreferrer" style={{ color: "white", background: "#0C66E4", textTransform: "none" }}>Email the timebank coordinator to sign up</Button>
          </div>)}
          <br></br><br></br>
          <p>Please review our privacy policy <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/Privacy.pdf">here</a> and terms of service <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/TOS.pdf">here</a></p><br></br>
          <p><i>This ride service is part of National Science Foundation funded study and is approved by the University of Michigan institutional review board # HUM00166267</i></p>
          <div className="split-column">
            <p style={{ fontSize: 24, fontWeight: 600 }}>My Profile</p>
            <Button style={{ textTransform: "none" }} onClick={() => { history.push(`/edit-driver-profile`) }}>
              <p style={{ fontWeight: "bold", color: "#3579F6" }}>
                Edit Profile
              </p>
            </Button>
          </div>
          <div className="driver-info-box-1">
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <ReactRoundedImage
                image={profile.image}
                roundedSize="0"
                imageWidth="50"
                imageHeight="50"
              />
              <p style={{ fontWeight: "600" }}>{profile.name}</p>
            </div>
            <br></br>
            <p style={{ fontWeight: "600" }}>My preferences</p>
            <br />
            <p>Spoken Languages: {languages}</p>
            <br />
            <p>Health-related safety preferences: {covidPreference}</p>
            <br />
            <p>Smoking preferences: {preferences.smoke}</p>
            <br />
            {/* <p>Vehicle assistance: {profile.vehicle_assistance}</p>
            <br /> */}
            <p>
              Allergies: {preferences.allergies}
            </p>
            <br />
            <p>Music: {preferences.music}</p>
            <br />
            <br />
            <div className="form-row">
              <p style={{ fontWeight: "600", paddingTop: "10px" }}>
                Vehicle Information
              </p><br></br>
              {/* <Button style={{ textTransform: "none" }}>
                <p style={{ fontWeight: "bold", color: "#3579F6" }}>
                  Edit details
                </p>
              </Button> */}
            </div>
            <br></br>
            <div className="form-row">
              <img
                src={vehicleInformation.image}
                style={{ width: "20rem" }}
              />
              {/* <img
                src={require("../images/car1.jpg")}
                style={{ width: "5rem" }}
              /> */}
            </div>
            {/* <br></br> */}
            {vehicleInformation.image !== "" && <p><i>Image uploaded on {vehicleInformation.uploadDate}</i></p>}
            <br></br><br></br>
            <p style={{ fontWeight: "bold" }}>{vehicleInformation.make}, {vehicleInformation.model} {vehicleInformation.year}</p>
            <p style={{ color: "#626F86", fontSize: "14px" }}>
              {vehicleInformation.number_plate}
            </p>
            <p>{vehicleInformation.specification}</p>
            <br></br>
          </div>
        </div>
      </div>
      {apiError && (
        <Alert severity="error">
          Request to server failed. Please try again
        </Alert>
      )}
    </div>
  );
};

export default DriverMyTrips;
