import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Alert, Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { setKey, geocode, RequestType } from "react-geocode";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
const RiderTripRequestInfo = ({ tripRequest, handleTripRequest }) => {
  const [date, setDate] = useState(dayjs());
  const [time, setTime] = useState(dayjs());

  const [pickupPoint, setPickupPoint] = useState("");
  const [pickupLocation, setPickupLocation] = useState({
    lat: 39.1653,
    lng: -86.526,
  });

  const [geoLocationAlert, setGeoLocationAlert] = useState(false);
  setKey("AIzaSyBTuMCbh2hnKXUw6PtafBZDGU-XNWs7vpI");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTuMCbh2hnKXUw6PtafBZDGU-XNWs7vpI",
  });

  const getCoordinates = async (value) => {
    setPickupPoint(value);
    try {
      let data= await geocode(RequestType.ADDRESS, value)
      console.log(data);
      if (data.status=="OK"){
        setPickupLocation({
          lat: data.results[0].geometry.location.lat,
          lng: data.results[0].geometry.location.lng,
        });
        setGeoLocationAlert(false);
      }else{
        setGeoLocationAlert(true)
      };
    } catch {
      setGeoLocationAlert(true);
      console.log("Error with geolocation");
    }
  };

  useEffect(()=>{
    if (tripRequest.pickup!==""){
      getCoordinates(tripRequest.pickup);
    }  
  },[])

  const [nullErrors, setNullErrors] = useState({})

  const isEmpty = (field, value) => {
    if (value.length === 0) {
      setNullErrors({
        ...nullErrors,
        [field]: true
      })
    } else {
      setNullErrors({
        ...nullErrors,
        [field]: false
      })
    }
  }

  const pickupSuggestions = [
    "Covenant Southwest Detroit: 5716 Michigan Avenue",
    "Unity in Our Community / Bridging Communities: 6900 McGraw Avenue",
    "Freedom House 1777 N Rademacher Street"
  ]

  const intersectionSuggestions = [
    "21 and Bagley", "Bagley and Rosa Parks", "Bagley and Trumbull", "Green and Lafayette Boulevard",
    "Green and Fort", "John Kronk and Central", "John Kronk and Lonyo", "Lafayette Boulevard and Waterman", 
    "Lafayette Boulevard and Livernois", "Lawndale and chamberlain", "Lawndale and Mandale", "Lawndale and Fort",
    "Michigan Livernois", "Michigan Central","Michigan and Junction", "Michigan and Martin", "McGraw and Martin",
    "McGraw Central", "Toledo and Junction","Toledo and Livernois", "Vernor and Clark", "Vernor and Junction",
    "Vernor and Lawndale", "Vernor and Spring Wells"," Vernor and Waterman", "Vernor and West Grand Boulevard", 
    "Vernor and Woodmere", "Woodmere and Elsmere", "Woodmere and Fort", "Woodmere and Chamberlain", "Scotten and Toledo",
    "Springwells and Lafayette Boulevard", "Springwells and Fort", "Vernor and Central", "Vernor and Green"
  ]

  const [stopPoints, setStopPoints] = useState(tripRequest.stopPoints);
  const [stopWaitTimes, setStopWaitTimes] = useState(tripRequest.stopWaitTimes);
  const [numberOfStops, setNumberOfStops] = useState(tripRequest.numberOfStops);


  useEffect(()=>{
    handleTripRequest("date", date.format("YYYY-MM-DD") + time.format(" HH:mm:ss"))
  },[])

  useEffect(() => {
    setStopPoints(prevStopPoints => {
      if (numberOfStops===0){
        return [];
      }
      
      if (prevStopPoints.length === numberOfStops) {
        return prevStopPoints;
      }
      else if (numberOfStops > prevStopPoints.length) {
        return [...prevStopPoints, ...new Array(numberOfStops - prevStopPoints.length).fill('')];
      }else {
        return prevStopPoints.slice(0,numberOfStops);
      }

    }) 
    setStopWaitTimes(prevStopWaitTimes => {
      if (numberOfStops===0){
        return [];
      }
      else if (prevStopWaitTimes.length === numberOfStops) {
        return prevStopWaitTimes;
      }
      else if (numberOfStops > prevStopWaitTimes.length) {
        return [...prevStopWaitTimes, ...new Array(numberOfStops - prevStopWaitTimes.length).fill('')];
      }else {
        return prevStopWaitTimes.slice(0,numberOfStops);
      }
    }) 

  }, [numberOfStops]);

  const handleStopPointsChange = (index, value) => {
    const newStops = [...stopPoints];
    newStops[index] = value;
    setStopPoints(newStops)
    handleTripRequest('stopPoints', newStops);
  };

  const handleStopWaitTimesChange = (index, value) => {
    const newStopWaitTimes = [...stopWaitTimes];
    newStopWaitTimes[index] = value;
    setStopWaitTimes(newStopWaitTimes)
    handleTripRequest('stopWaitTimes', newStopWaitTimes);
  };

  // const [selectedDate, setselectedDate] = useState(dayjs());
  return (
    <div>
      <div className="split-body-equal-left-unpadded">
        <div>
          <p style={{ fontSize: 24, fontWeight: 600 }}>My Trip Request</p>
          <br></br>
          <br />

          <div>
            <div className="form-row">
              <div className="form-column">
                <p>Pick-up location<br></br> <i>Your address won't be displayed to the driver until the request is accepted</i></p>
                <p>Enter a nearby intersection<br></br> <i>Replaces location with a nearby intersection to keep your address private. You will meet your driver at this intersection.</i></p>
                <p>Drop-off location</p>
                <div style={{display:"flex", flexDirection:"column", gap:"3.5rem"}}>
                  <p>Select date of trip</p>
                  <p>Pick-up time</p>
                  <p>Appointment time</p>
                </div>
                <p>Trip Type</p>
                <p>Additional trip details</p>
                <p>Wait time during appointment</p>
                <p>Number of extra stops</p>
                {/* <div className="form-column">
                  {stopPoints.map((_, index) => (<div className="form-column" key={index}>
                    <p>Address #{index + 1}</p>
                    <p>Wait time for the stop</p>
                  </div>))}
                </div> */}
                {tripRequest.numberOfStops>=1 && (<div className="form-column">
                    <p>Address #1</p>
                    <p>Wait time for the stop</p>
                </div>)}
                {tripRequest.numberOfStops>=2 && (<div className="form-column">
                    <p>Address #2</p>
                    <p>Wait time for the stop</p>
                </div>)}
                {tripRequest.numberOfStops>=3 && (<div className="form-column">
                    <p>Address #3</p>
                    <p>Wait time for the stop</p>
                </div>)}
              </div>
              <div className="form-column">
                <Autocomplete
                  freeSolo
                  options={pickupSuggestions}
                  value={tripRequest.pickup}
                  onChange={(e, v) => {
                    isEmpty("pickup", v || "");
                    handleTripRequest("pickup", v || "");
                  }}
                  onBlur={(e) => {
                    if (nullErrors.pickup === false) {
                      getCoordinates(e.target.value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={nullErrors.pickup}
                      helperText={nullErrors.pickup ? "Pickup cannot be empty" : ''}
                      className="text-component"
                      hiddenLabel
                      id="filled-hidden-label-small"
                      size="small"
                      placeholder="Pickup point"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: "off"
                      }}
                      value={tripRequest.pickup}
                      onChange={(e) => {
                        isEmpty("pickup", e.target.value);
                        handleTripRequest("pickup", e.target.value);
                      }}
                      onBlur={(e) => {
                        if (nullErrors.pickup === false) {
                          getCoordinates(e.target.value);
                        }
                      }}
                    />
                  )}
                /><br></br>
                <Autocomplete
                  freeSolo
                  options={intersectionSuggestions}
                  value={tripRequest.nearbyIntersection}
                  onChange={(e, v) => {
                    handleTripRequest("nearbyIntersection", v || "");
                    isEmpty("nearbyIntersection", v || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={nullErrors.nearbyIntersection}
                      helperText={nullErrors.nearbyIntersection ? "Nearby Intersection cannot be empty" : ''}
                      className="text-component"
                      hiddenLabel
                      id="filled-hidden-label-small"
                      size="small"
                      placeholder="Nearby Intersection"
                      value={tripRequest.nearbyIntersection}
                      onChange={(e) => {
                        handleTripRequest("nearbyIntersection", e.target.value);
                      }}
                      onBlur={(e) => {
                        isEmpty("nearbyIntersection", e.target.value);
                      }}
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: "off"
                      }}
                    />
                  )}
                /><br></br>
                <Autocomplete
                  freeSolo
                  options={pickupSuggestions}
                  value={tripRequest.destination}
                  onChange={(e, v) => {
                    isEmpty("destination", v || "");
                    handleTripRequest("destination", v || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={nullErrors.destination}
                      helperText={nullErrors.destination ? "Destination cannot be empty" : ''}
                      className="text-component"
                      hiddenLabel
                      id="filled-hidden-label-small"
                      size="small"
                      placeholder="Destination point"
                      value={tripRequest.destination}
                      onChange={(e) => {
                        handleTripRequest("destination", e.target.value);
                      }}
                      onBlur={(e) => {
                        isEmpty("destination", e.target.value);
                      }}
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: "off"
                      }}
                    />
                  )}
                />
                <div className="form-column">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker slotProps={{ textField: { size: 'small' } }}  disablePast format="MM/DD/YYYY" value={date} onChange={(e) => {
                      setDate(e);
                      handleTripRequest("date", e.format("YYYY-MM-DD") + time.format(" HH:mm:ss"))
                    }} />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker slotProps={{ textField: { size: 'small' } }} value={time} onChange={(e) => {
                      setTime(e);
                      handleTripRequest("date", date.format("YYYY-MM-DD") + e.format(" HH:mm:ss"))
                    }} />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker slotProps={{ textField: { size: 'small' } }} value={tripRequest.appointmentTime} onChange={(e) => {
                      handleTripRequest("appointmentTime", e.format("HH:mm"))
                    }} />
                  </LocalizationProvider>
                </div>
                <Select
                  className="text-component"
                  value={tripRequest.type}
                  onChange={(e) => {
                    handleTripRequest("type", e.target.value);
                  }}
                  style={{ height: 30 }}
                >
                  <MenuItem value={"One-way trip"}>
                    One-way trip
                  </MenuItem>
                  <MenuItem value={"Round trip"}>
                    Round trip
                  </MenuItem>
                </Select>
                <TextField
                  className="text-component"
                  hiddenLabel
                  id="filled-hidden-label-small"
                  size="small"
                  placeholder="Comments"
                  value={tripRequest.comments}
                  onChange={(e) => {
                    handleTripRequest("comments", e.target.value);
                  }}
                />
                <Select
                  className="text-component"
                  value={tripRequest.waitTime}
                  onChange={(e) => {
                    handleTripRequest("waitTime", e.target.value);
                  }}
                  style={{ height: 30 }}
                >
                  <MenuItem value={"Less than an hour"}>
                    Less than an hour
                  </MenuItem>
                  <MenuItem value={"More than an hour"}>
                    More than an hour
                  </MenuItem>
                </Select>
                <Select
                  className="text-component"
                  value={tripRequest.numberOfStops}
                  onChange={(e) => {
                    setNumberOfStops(e.target.value);
                    handleTripRequest("numberOfStops", e.target.value);
                  }}
                  style={{ height: 30 }}
                >
                  <MenuItem value={0}>None</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
                <div className="form-column">
                  {(stopPoints || []).map((stop, index) => (<div className="form-column" key={index}>
                    <TextField
                      className="text-component"
                      hiddenLabel
                      id="filled-hidden-label-small"
                      size="small"
                      placeholder="Address"
                      value={stopPoints[index]}
                      style={{ height: 30 }}
                      onChange={(e) => {
                        handleStopPointsChange(index, e.target.value);
                      }}
                    />
                    <Select
                      className="text-component"
                      value={stopWaitTimes[index]}
                      onChange={(e) => {
                        handleStopWaitTimesChange(index, e.target.value);
                      }}
                      style={{ height: 30 }}
                    >
                      <MenuItem value={"Less than 1/2 hour"}>
                        Less than 1/2 hour
                      </MenuItem>
                      <MenuItem value={"More than 1/2 hour"}>
                        More than 1/2 hour
                      </MenuItem>
                    </Select>
                  </div>))}
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <br />
        </div>

        <div>
          <p style={{ fontSize: 24, fontWeight: 600 }}>Map</p>
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              center={pickupLocation}
              zoom={10}
            >
              <Marker position={pickupLocation} icon={'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'}/>
            </GoogleMap>
          )}
          {geoLocationAlert && (
            <Alert severity="error">Please enter valid pickup address</Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default RiderTripRequestInfo;
