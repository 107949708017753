import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Popup from "./Popup";
import axios from "axios";
import { useHistory } from "react-router-dom";

const DriverPreparation = ({ id }) => {
  const [appointmentChecked, setAppointmentChecked] = useState(false);
  const [riderTaskPopUp, setRiderTaskPopup] = useState(false);
  const [driverTaskPopUp, setDriverTaskPopup] = useState(false);
  const [markPopUp, setMarkPopup] = useState(false);
  let driverID = 0;

  const [riderList, setRiderList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [apiError, setAPIError] = useState(false);

  let driverNewTask, riderNewTask;
  const history = useHistory();

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  try {
    driverID = window.sessionStorage.getItem("driverID");
  } catch (e) {
    //TODO: Alert
    console.log("Failed to get driver ID");
  }

  const openRiderTaskPopup = () => {
    setRiderTaskPopup(true);
  };

  const closeRiderTaskPopup = () => {
    setRiderTaskPopup(false);
  };

  const openDriverTaskPopup = () => {
    setDriverTaskPopup(true);
  };

  const closeDriverTaskPopup = () => {
    setDriverTaskPopup(false);
  };

  const openMarkPopup = () => {
    setMarkPopup(true);
  };

  const closeMarkPopup = () => {
    setMarkPopup(false);
  };

  const handleAppointmentChange = (e) => {
    setAppointmentChecked(e.target.checked);
  };

  const handleMarking = () => {
    return;
  };

  const updateChecklist = async (dList, rList) => {
    await axios
      .put(
        `https://iuhw-app.hourworld.org/trip-request/${id}/checklist`,
        {
          trip_id: id,
          driver_checklist: dList,
          rider_checklist: rList,
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        throw e;
      });
  }

  const handleNewDriverTask = () => {
    setDriverList((driverList) => [...driverList,
    {
      key: driverNewTask,
      value: false,
    }
    ]);

    const dList = [...driverList,
    {
      key: driverNewTask,
      value: false,
    }
    ]

    try {
      updateChecklist(dList, riderList);
    } catch (e) {
      return;
    }

    closeDriverTaskPopup();
    return;
  };

  const handleNewRiderTask = () => {
    setRiderList((riderList) => [...riderList,
    {
      key: riderNewTask,
      value: false,
    }
    ]);

    const rList = [...riderList,
    {
      key: riderNewTask,
      value: false,
    }
    ]

    try {
      updateChecklist(driverList, rList);
    } catch (e) {
      return;
    }

    closeRiderTaskPopup();
    return;
  };

  useEffect(() => {
    const getChecklist = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/trip-request/" + id + "/checklist")
        .then((response) => {
          setRiderList(response.data.rider_checklist);
          setDriverList(response.data.driver_checklist);
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });

    };

    getChecklist();
  }, []);

  const handleCheckboxChange = (index) => (e) => {
    const newdList = [...driverList];
    newdList[index].value = e.target.checked;
    setDriverList(newdList);
    updateChecklist(newdList, riderList);
  };

  return (
    <div className="rider-profile-body">
      <p style={{ fontSize: 24, fontWeight: 600 }}>Trip Preparation Checklist</p>
      <div className="driver-split">
        <div>
          <div className="driver-info-box">
            <p style={{ fontWeight: "600" }}>
              Driver's Checklist to complete before the trip
            </p>
            <br></br>
            <div style={{ paddingLeft: "2rem" }}>
              {driverList.map((item, index) => (
                <><FormControlLabel
                  key={index}
                  control={<Checkbox checked={item.value} onChange={handleCheckboxChange(index)} />}
                  label={item.key}
                /><br></br></>
              ))}
            </div>
            <br></br>
            <Button onClick={openDriverTaskPopup} style={{ textTransform: "none" }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "#3579F6",
                }}
              >
                Add task
              </p>
            </Button>
            <br></br>
            <br></br>
            {/* <Button
              variant="contained"
              onClick={openMarkPopup}
              style={{
                color: "white",
                background: "#0C66E4",
                textTransform: "none",
              }}
            >
              Mark all done
            </Button> */}
          </div>
        </div>
        <Popup openPopup={driverTaskPopUp}>
          <div>
            <p className="heading"> Add task to the checklist</p>
            <br></br>
            <p>The person will be notified of the new task</p>
            <br></br>
            <TextField
              className="text-component"
              hiddenLabel
              id="filled-hidden-label-small"
              size="small"
              placeholder="Add task"
              value={driverNewTask}
              onChange={(e) => {
                driverNewTask = e.target.value;
              }}
            />
            <div
              style={{
                paddingTop: "3rem",
                display: "flex",
                flexDirection: "row",
                gap: "50%",
              }}
            >
              <Button
                variant="contained"
                onClick={closeDriverTaskPopup}
                style={{
                  color: "black",
                  background: "#091E4206",
                  textTransform: "none",
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  background: "#0C66E4",
                  textTransform: "none",
                }}
                onClick={handleNewDriverTask}
              >
                Add Task
              </Button>
            </div>
          </div>
        </Popup>
        <Popup
          openPopup={markPopUp}
        >
          <div>
            <p className="heading"> Completed the checklist?</p>
            <br></br>
            <p>Are you sure you wish to start the trip without fulfilling the checklist</p><br></br>
            <div
              style={{
                paddingTop: "3rem",
                display: "flex",
                flexDirection: "row",
                gap: "50%",
              }}
            >
              <Button
                variant="contained"
                onClick={closeMarkPopup}
                style={{
                  color: "black",
                  background: "#091E4206",
                  textTransform: "none",
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  background: "#0C66E4",
                  textTransform: "none",
                }}
                onClick={handleMarking}
              >
                Yes, I understand
              </Button>
            </div>
          </div>
        </Popup>
        <div>
          <div className="driver-info-box">
            <p style={{ fontWeight: "600" }}>Rider's Checklist</p>
            <br></br>
            <div style={{ paddingLeft: "2rem" }}>
              {riderList.map((item, index) => (
                <><FormControlLabel
                  key={index}
                  control={<Checkbox checked={item.value} disabled />}
                  label={item.key}
                /><br></br></>
              ))}
            </div>
            <br></br>
            <Button onClick={openRiderTaskPopup} style={{ textTransform: "none" }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "#3579F6",
                }}
              >
                Add task
              </p>
            </Button>
            <Popup openPopup={riderTaskPopUp}>
              <div>
                <p className="heading"> Add task to the checklist</p>
                <br></br>
                <p>The person will be notified of the new task</p>
                <br></br>
                <TextField
                  className="text-component"
                  hiddenLabel
                  id="filled-hidden-label-small"
                  size="small"
                  placeholder="Add task"
                  value={riderNewTask}
                  onChange={(e) => {
                    riderNewTask = e.target.value;
                  }}
                />
                <div
                  style={{
                    paddingTop: "3rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "50%",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={closeRiderTaskPopup}
                    style={{
                      color: "black",
                      background: "#091E4206",
                      textTransform: "none",
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      color: "white",
                      background: "#0C66E4",
                      textTransform: "none",
                    }}
                    onClick={handleNewRiderTask}
                  >
                    Add Task
                  </Button>
                </div>
              </div>
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverPreparation;
