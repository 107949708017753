import { Button, Checkbox, FormControlLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import ReactRoundedImage from "react-rounded-image";
import React from 'react';
import { useHistory } from "react-router-dom";
import Popup from "./Popup";

const RiderProfileInfo = ({ profile, handleProfile, imageFile, handleImageInput, languages, handleLanguages }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const history = useHistory();
  const handleImage = (file) => {
    handleImageInput(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  handleImage(imageFile);

  const removeImage = () => {
    handleImageInput(null);
    setImagePreview(null);
    handleProfile("image", "");
  }

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  const fileInputRef = React.createRef();

  const handleFileInput = () => {
    fileInputRef.current.click();
  };

  const [contactError, setContactError] = useState(null);
  const checkContact = (value) => {
    if (!/^[0-9]{10}$/.test(value)) {
      setContactError(true);
    } else {
      setContactError(false);
    }
  };

  const [popup,setPopup]=useState(false);

  return (
    <div className="rider-profile-body">
      <p style={{ fontSize: 24, fontWeight: 600 }}>Setting Up Your Profile</p>
      <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <ReactRoundedImage
          image={imagePreview || profile.image}
          alt="Profile Picture"
          roundedSize="0"
          imageWidth="70"
          imageHeight="70"
        />
        <div className="form-column-1">
          <p>{profile.name}</p>
          <div>
            <Button
              variant="contained"
              onClick={handleFileInput}
              style={{
                color: "white",
                background: "#0C66E4",
                textTransform: "none",
              }}
            >
              Upload picture
            </Button>
            <input style={{ display: "none" }} ref={fileInputRef} type="file" name="image" onChange={(e) => { handleImage(e.target.files[0]) }} />
            <Button style={{ textTransform: "none" }} onClick={removeImage}>
              <p style={{ fontWeight: "bold", color: "red" }}>Remove picture</p>
            </Button>
            <br></br><br></br>
            <p>Please ignore if you prefer not to post a picture</p>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-column">
          <p>Pronouns</p>
          <p>Phone Number</p>
          <p>Preferred method of communication</p>
          <div className="form-column">
            <p>What languages do you speak?</p>
            <p>Another language not listed:</p>
          </div>
        </div>

        <div className="form-column">
          <TextField
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={profile.pronoun}
            placeholder="she/her"
            onChange={(e) => {
              handleProfile("pronoun", e.target.value);
            }}
          />
          <TextField
            className="text-component"
            hiddenLabel
            error={contactError}
            helperText={contactError ? "Please enter a valid 10 digit phone number without any extra characters" : ''}
            id="filled-hidden-label-small"
            size="small"
            value={profile.mobile}
            placeholder="80236498745"
            onChange={(e) => {
              handleProfile("mobile", e.target.value);
            }}
            onBlur={(e) => {
              checkContact(e.target.value);
            }}
          />
          <Select
            className="text-component"
            value={profile.preferredCommunication}
            onChange={(e) => {
              handleProfile("preferredCommunication", e.target.value);
              if (e.target.value==="Email"){
                setPopup(true);
              }else{
                setPopup(false);
              }
            }}
            style={{ height: 40 }}
          >
            <MenuItem value={"Mobile"}>Mobile</MenuItem>
            <MenuItem value={"Email"}>Email</MenuItem>
          </Select>
          <Popup
              openPopup={popup}
            >
              <div>
                The email you used to logged into the timebank will be used.
                <div
                  style={{
                    paddingTop: "2rem",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setPopup(false);
                    }}
                    style={{
                      color: "black",
                      background: "#091E4206",
                      textTransform: "none",
                    }}
                  >
                    Okay
                  </Button>
                </div>
              </div>
          </Popup>
          <div className="form-column">
            <div className="form-row">
              <FormControlLabel
                control={<Checkbox checked={languages.english} />}
                label="English"
                onChange={(e) => {
                  handleLanguages("english", e.target.checked);
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={languages.arabic} />}
                label="Arabic"
                onChange={(e) => {
                  handleLanguages("arabic", e.target.checked);
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={languages.spanish} />}
                label="Spanish"
                onChange={(e) => {
                  handleLanguages("spanish", e.target.checked);
                }}
              />
            </div>
            <TextField
              className="text-component"
              hiddenLabel
              id="filled-hidden-label-small"
              size="small"
              value={languages.other}
              placeholder="Greek"
              onChange={(e) => {
                handleLanguages("other", e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      {/* <div className="form-row">
        <div className="form-column">
          <p>Add a profile picture</p>
          <p>Do you want to provide ride updates to a time bank buddy?</p>
          <p>Select your time bank buddy</p>
        </div>
        <div className="form-column">
          <Button iconBefore={<UserAvatarCircleIcon label="" size="medium" />}>
            <p style={{ fontWeight: "bold", color: "#3579F6" }}>
              Add profile picture
            </p>
          </Button> */}
      {/* <p><input value="yes" label="Yes" type="checkbox" onChange={handleChange} /> Yes</p>  */}
      {/* <FormControlLabel
              control={<Checkbox />}
              label="Yes"
              onChange={handleChange}
            />
            <Select className="text-component" value={timeBankBuddy} onChange={handleSelect}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"John Osario"}>John Osario</MenuItem>
              <MenuItem value={"Nehul Rangappa"}>Nehul Rangappa</MenuItem>
            </Select> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default RiderProfileInfo;
