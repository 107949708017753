import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const DriverHomePage = () => {
  const history = useHistory();

  // try{
  //   if (window.sessionStorage.getItem("bearerToken")===null){
  //     history.push("/login");
  //   };
  // }catch(e){
  //   history.push("/login");
  // }

  const handleLater = () => {
    // history.push("/driver-welcome");
    history.push("/driver-profile");
  }

  return (
    <div className="profile">
      <div className="body">
        <p className="heading" style={{ fontWeight: "bold" }}>
          Let's Get Started
        </p>
        <br />
        <p className="subHeading">Join as a timebank driver</p>
      </div>
      <div className="profile-home">
        <p style={{ fontSize: "24px", fontWeight: "bold" }}>
          Thanks for signing up to become a Timebank driver!
        </p>
        <br />
        <p>
          To start giving rides, you need to take the Unity in Our Community
          driver taking course.
        </p>
      </div>
      <div className="form-container">
        <div className="form-footer">
          <Button variant="contained" style={{ color: "black", background: "#091E4206", textTransform: "none" }} onClick={handleLater}>Yes, I understand</Button>
          <Button variant="contained" component="a" href="mailto:uoc.timebank@gmail.com" target="_blank"
            rel="noopener noreferrer" style={{ color: "white", background: "#0C66E4", textTransform: "none" }}>Email the timebank coordinator to sign up</Button>
        </div>
      </div>
    </div>
  );
};

export default DriverHomePage;
