import { Alert, TextField } from "@mui/material";
import axios from "axios";
import { useState} from "react";
import { useHistory } from "react-router-dom";

const AdminPage = () => {
  const history = useHistory();
  const [accessCode, setAccessCode] = useState("");
  const [apiError, setAPIError] = useState(false);

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  const getAllData = async () => {
    await axios
    .get(
        "https://iuhw-app.hourworld.org/admin/trip-data",
        { headers: { "Authorization": accessCode }, responseType: 'blob' }
    )
    .then((response) => {
      const blob = new Blob([response.data], { type: 'text/csv' });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      link.href = url;
      link.download = 'trip-data.csv';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }).catch((e) =>{
        console.log(e);
        setAPIError(true);
    });
  }

  const getDriverReviews = async () => {
    await axios
    .get(
        "https://iuhw-app.hourworld.org/admin/driver-reviews",
        { headers: { "Authorization": accessCode }, responseType: 'blob' }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
  
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        link.href = url;
        link.download = 'driver-reviews.csv';
  
        document.body.appendChild(link);
        link.click();
  
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }).catch((e) =>{
          console.log(e);
          setAPIError(true);
      });
    }

  const getRiderReviews = async () => {
    await axios
    .get(
        "https://iuhw-app.hourworld.org/admin/rider-reviews",
        { headers: { "Authorization": accessCode }, responseType: 'blob' }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });

        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        link.href = url;
        link.download = 'rider-reviews.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }).catch((e) =>{
          console.log(e);
          setAPIError(true);
      });
  }

  return (
    <div className="home">
      <div className="welcome">
        <p className="heading">HourWorld Exchange</p>
      </div>
      <div className="content">
        <p className="heading" style={{ fontWeight: "bold" }}>
          Hello!
        </p>
        <br />
        <p className="subHeading">
          Please enter the access code to download the relevant transportation data
        </p>
      </div>
      <div style={{ paddingLeft: "40%", paddingBottom: "1rem" }}>
        <div className="login-card" style={{ width: "35%" }}>
          <div style={{ padding: "2rem" }}>
            <p style={{ paddingTop: "1rem", paddingBottom: "0.5rem", fontWeight: "bold" }}>
              Access Code</p>
            <TextField
              style={{ backgroundColor: "#e8effd" }}
              className="text-component"
              hiddenLabel
              id="filled-hidden-label-small"
              size="small"
              value={accessCode}
              placeholder="abc123"
              onChange={(e) => {
                setAccessCode(e.target.value);
              }}
            />
            {apiError && (<div style={{ padding: "1rem" }}>
              <Alert severity="error">
                Verification failed. Please try again
              </Alert>
            </div>)
            }
            <div style={{ paddingTop: "1rem", display:"flex", flexDirection:"column" }} >
              <button className="cardButtonSmall" onClick={getAllData}>
                Download Complete Data
              </button>
              <button className="cardButtonSmall" onClick={getDriverReviews}>
                Download Driver Reviews
              </button>
              <button className="cardButtonSmall" onClick={getRiderReviews}>
                Download Rider Reviews
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{paddingLeft:"10%"}}>
        <p>Please review our privacy policy <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/Privacy.pdf">here</a> and terms of service <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/TOS.pdf">here</a></p><br></br>
        <p><i>This ride service is part of National Science Foundation funded study and is approved by the University of Michigan institutional review board # HUM00166267</i></p>
      </div>
    </div>
  );
};

export default AdminPage;
