import { Button } from "@mui/material";
import ReactRoundedImage from "react-rounded-image";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
const DriverProfilePreview = () => {
  const history = useHistory();
  const [apiError, setAPIError] = useState(false);
  const driverID = window.sessionStorage.getItem("driverID");

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  const handleEdit = () => {
    history.push("/home");
  };

  const handlePublish = () => {
    history.push("/home");
  };

  const [profile, setProfile] = useState({});
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [preferences, setPreferences] = useState({});

  useEffect(() => {
    const getDriverProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/driver/" + driverID)
        .then((response) => {
          setProfile({
            ...response.data.profile,
          });
          setVehicleInfo({
            ...response.data.vehicleInformation,
          });
          setPreferences({
            ...response.data.preferences,
          });
          // window.sessionStorage.setItem(
          //   "driverProfile",
          //   JSON.stringify(response.data)
          // );
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };

    getDriverProfile();
  }, []);

  return (
    <div className="profile">
      <div className="body">
        <p className="heading" style={{ fontWeight: "bold" }}>
          Profile Preview
        </p>
        <br />
        <p className="subHeading">Let's get your trips noticed!</p>
      </div>
      <div style={{ paddingTop: "3rem" }}>
        <div
          className="split-body-equal-left-unpadded"
          style={{ paddingLeft: "1rem", paddingBottom: "2rem" }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <ReactRoundedImage
                image={profile.image}
                roundedSize="0"
                imageWidth="60"
                imageHeight="60"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <p style={{ fontWeight: "600" }}>{profile.name}</p>
                <p>{profile.pronoun}</p>
              </div>
            </div>
            <br />
            <br />
            <div className="form-row">
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Maximum trip distance
                </p>
                <br></br>
                <p>{preferences.max_trip_distance} miles</p>
                <br></br>
              </div>
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Maximum number of stops
                </p>
                <br></br>
                <p>{preferences.max_number_of_stops}</p>
                <br></br>
              </div>
            </div>
            <div className="form-row">
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Maximum time available
                </p>
                <br></br>
                <p>{preferences.max_time_available} hours</p>
                <br></br>
              </div>
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Smoking preferences
                </p>
                <br></br>
                <p>{preferences.smoke}</p>
                <br></br>
              </div>
            </div>
            <div>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>Allergies</p>
              <br></br>
              <p>{preferences.allergies}</p>
              <br></br>
            </div>
            {/* <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row",
                gap: "12rem",
                paddingTop: "1rem",
              }}
            > */}
            {/* <Button
                variant="contained"
                onClick={handleEdit}
                style={{
                  color: "black",
                  background: "#091E4206",
                  textTransform: "none",
                }}
              >
                Edit Information
              </Button> */}
            <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row",
                gap: "2rem",
              }}
            >
              {/* <Button style={{ textTransform: "none" }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#3579F6",
                    }}
                  >
                    Skip for now
                  </p>
                </Button> */}
              <Button
                variant="contained"
                style={{
                  color: "white",
                  background: "#0C66E4",
                  textTransform: "none",
                }}
                onClick={handlePublish}
              >
                Go to home
              </Button>
            </div>
            {/* </div> */}
          </div>
          <div>
            <img
              src={vehicleInfo.image}
              style={{ width: "20rem" }}
            />
            <br></br>
            <br></br>
            <p style={{ fontWeight: "600" }}>Vehicle Information</p>
            <br />
            <p>
              {vehicleInfo.make} {vehicleInfo.model} {vehicleInfo.year}-{" "}
              {vehicleInfo.specification}
            </p>
            <br />
          </div>
        </div>
      </div>
      {apiError && (
        <Alert severity="error">
          Request to server failed. Please try again
        </Alert>
      )}
    </div>
  );
};

export default DriverProfilePreview;
