import { Button, Checkbox, FormControlLabel, MenuItem, Rating, Select, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactRoundedImage from "react-rounded-image";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import HandshakeIcon from '@mui/icons-material/Handshake';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Popup from "./Popup";

const DriverTripComplete = () => {
  const [comments, setComments] = useState("");
  const [rating, setRating] = useState(0);

  const [profile, setProfile] = useState({});
  const [riderProfile, setRiderProfile] = useState({});
  const [tripDetails, setTripDetails] = useState({});
  const driverID = window.sessionStorage.getItem("driverID");
  const [apiError, setAPIError] = useState(false);
  const history = useHistory();

  const { id } = useParams();

  const [emotion, setEmotion] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const riderID = queryParams.get('riderID');

  // const [safetyConcern, setSafetyConcern] = useState("");
  // const [safetyAccusation, setSafetyAccusation] = useState("");

  // const [popup,setPopup]=useState(false);

  useEffect(() => {
    const getTripDetails = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/trip-details/" + id + "?driverID=" + driverID)
        .then((response) => {
          setTripDetails({
            ...response.data,
          });
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getTripDetails();
  }, []);

  useEffect(() => {
    const getRiderProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/rider/" + riderID)
        .then((response) => {
          setRiderProfile({
            ...response.data.profile,
          });
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getRiderProfile();
  }, []);

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  useEffect(() => {
    const getDriverProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/driver/" + driverID)
        .then((response) => {
          setProfile({
            ...response.data.profile,
          });
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getDriverProfile();
  }, []);

  const handleSubmit = async () => {
    if (riderID===null){
      return;
    }

    const body = {
      name: profile.name,
      rating: rating,
      review: comments,
      emotion: emotion,
      // safetyConcern: safetyConcern,
      // safetyAccusation: safetyAccusation,
      tripID: id,
      driverID: driverID,
      riderID: riderID,
    };

    if (favorite===true){
      handleFavourites();
    }

    await axios
      .post("https://iuhw-app.hourworld.org/rider-review", body)
      .then(() => {
        //TODO: Alert
        history.push("/home");
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });
  };

  const [favorite, setFavorite]=useState(false);

  const handleFavourites = async () => {
    if (riderID===null){
      return;
    }

    const body = {
      driverID: driverID,
      riderID: riderID,
    };

    await axios
      .post("https://iuhw-app.hourworld.org/driver/" + driverID + "/favourites", body)
      .then(() => {
        setAPIError(false);
        // setPopup(true);
      })
      .catch((e) => {
        console.log(e);
        setAPIError(true);
      });
  };

  return (
    <div className="profile">
      <div className="body">
        <p className="heading" style={{ fontWeight: "bold" }}>
          Trip Completed Successfully
        </p>
      </div>
      <div style={{ paddingBottom: "3rem" }}>
        <br></br>
        <p>
          {" "}
          Hope you had a wonderful experience. If you enjoyed your trip, don't
          forget to show your appreciation to the rider.
        </p>
        <br></br>
        <br></br>
        <p>
          <b>Start Time:</b>
          {tripDetails.start_time}
        </p>
        <br></br>
        <p>
          <b>Time of completion:</b> {tripDetails.completion_time}
        </p>
        <br></br>
        <p>
          <b>Total hours spent:</b> {tripDetails.trip_time}
        </p>
      </div>
      <p style={{ fontSize: 20, fontWeight: 600, paddingBottom: "2rem" }}>
        Add a public review
      </p>
      <div className="driver-info-box">
        <div className="rider-profile-body">
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <ReactRoundedImage
              image={riderProfile.image}
              roundedSize="0"
              imageWidth="50"
              imageHeight="50"
            />
            <p style={{ fontWeight: "600" }}>{riderProfile.name}</p>
          </div>
          <Rating
            name="rider-rating"
            precision={0.5}
            value={rating}
            sx={{
              '& .MuiRating-icon': {
                fontSize: '2rem',
              },
            }}
            onChange={(e) => {
              setRating(e.target.value);
            }}
          />{" "}
          <p style={{ fontWeight: "600" }}>
            Provide us your public feedback of {riderProfile.name}
          </p>
          <TextField
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="Comments"
            value={comments}
            onChange={(e) => {
              setComments(e.target.value);
            }}
          />
        </div>
      </div>
      <div style={{paddingTop:"3rem"}}>
        <p style={{ fontSize: 20, fontWeight: 600, paddingBottom:"20px"}}>
          Send Your Appreciation to the Rider
        </p>
        <div style={{ display: "flex", flexDirection: "row", gap: "5%" }}>
          <FormControlLabel
            key="Good"
            control={<Checkbox
              checked={emotion === "Good"}
              icon={<ThumbUpIcon fontSize="large" />}
              checkedIcon={<ThumbUpIcon fontSize="large" />} />}
            onChange={() => {
              setEmotion("Good");
            }}
          />
          <FormControlLabel
            key="Handshake"
            control={<Checkbox
              checked={emotion === "Handshake"}
              icon={<HandshakeIcon fontSize="large" />}
              checkedIcon={<HandshakeIcon fontSize="large" />} />}
            onChange={() => {
              setEmotion("Handshake");
            }}
          />
          <FormControlLabel
            key="Satisfied"
            control={<Checkbox
              checked={emotion === "Satisfied"}
              icon={<SentimentSatisfiedAltIcon fontSize="large" />}
              checkedIcon={<SentimentSatisfiedAltIcon fontSize="large" />} />}
            onChange={() => {
              setEmotion("Satisfied");
            }}
          />
        </div>
      </div>
      <div style={{paddingTop:"2rem", display:"flex", direction:"row", gap:"50px"}}>
        <p style={{ fontSize: 20, fontWeight: 600}}>
          Add the rider to your list of favorites?
        </p>
        <Select
            className="text-component"
            value={favorite}
            onChange={(e) => {
              setFavorite(e.target.value)
            }}
            style={{ height: 30 }}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
      </div>
      <div style={{paddingTop:"2rem"}}>
        <p style={{ fontSize: 20, fontWeight: 600, paddingBottom: "20px" }}>
          Report Safety Concerns to the Timebank
        </p>
        <p>Please email your concerns to the timebank at <a href="mailto:uoc.timebank@gmail.com" target="_blank" rel="noopener noreferrer">uoc.timebank@gmail.com</a></p>
      </div>
      <div style={{paddingTop:"3rem", display:"flex", direction:"center", gap:"20%"}}>
        {/* <Button
          variant="contained"
          onClick={handleFavourites}
          style={{ color: "white", background: "#0C66E4", textTransform: "none" }}
          startIcon={<FavoriteIcon/>}
        >
          Add rider to favorites
        </Button> */}
        <Button
          variant="contained"
          onClick={()=>{history.push("/driver-my-trips")}}
          style={{
            color: "black",
            background: "#091E4206",
            textTransform: "none",
          }}
        >
          Skip review
        </Button>    
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{ color: "white", background: "#0C66E4", textTransform: "none" }}
        >
          Submit feedback
        </Button>
      </div>
      {/* <Popup
        openPopup={popup}
      >
        <div>
          Rider has been added to your list of favorites
          <div
            style={{
              paddingTop: "2rem",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                setPopup(false);
              }}
              style={{
                color: "black",
                background: "#091E4206",
                textTransform: "none",
              }}
            >
              Okay
            </Button>
          </div>
        </div>
      </Popup> */}
      {apiError && (
        <Alert severity="error">
          Request to server failed. Please try again
        </Alert>
      )}
    </div>
  );
};

export default DriverTripComplete;
