import { Alert, TextField } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const Login = () => {
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [apiError, setAPIError] = useState(false);
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const eID = queryParams.get("eID");
  const [timebank, setTimeBank] = useState("");

  try {
    if (window.sessionStorage.getItem("bearerToken") !== null) {
      history.push(`/home?eID=${eID}`);
    };
  } catch (e) {
    console.log(e);
  }

  const handleUsername = (value) => {
    setUsername(value);
  }

  const handlePassword = (value) => {
    setPassword(value);
  }

  const checkEmail = (value) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  useEffect(() => {
    const getTimeBank = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/time-bank?eID=" + eID)
        .then((response) => {
          setTimeBank(response.data.timebank);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    getTimeBank();
  }, [eID]);

  const handleSubmit = async () => {
    const requestBody = {
      "username": username,
      "password": password
    }

    await axios
      .post(
        "https://iuhw-app.hourworld.org/login?eID="+ eID,
        requestBody
      )
      .then((response) => {
        window.sessionStorage.setItem("userID", response.data.id)
        window.sessionStorage.setItem("eID", response.data.eID)
        window.sessionStorage.setItem("memID", response.data.memID)
        window.sessionStorage.setItem("bearerToken", response.data.bearerToken)
        setAPIError(false);
        history.push(`/home?eID=${response.data.eID}&memID=${response.data.memID}&userID=${response.data.id}&bearerToken=${response.data.bearerToken}`);
        // history.push(`/home?eID=${eID}`);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });
  }

  return (
    <div className="home">
      <div className="welcome">
        <p className="heading">Hour Exchange: {timebank}</p>
        <br />
        <p className="subHeading">
          Helping neighbors build community one hour at a time
        </p>
      </div>
      <div className="content">
        <p className="heading" style={{ fontWeight: "bold" }}>
          Hello!
        </p>
        <br />
        <p className="subHeading">
          Please login to use the ride-sharing service
        </p>
      </div>
      <div style={{ paddingLeft: "40%", paddingBottom: "1rem" }}>
        <div className="login-card" style={{ width: "35%" }}>
          <div style={{ padding: "2rem" }}>
            {/* <p style={{fontSize:"20px", fontWeight:"bold", textAlign:"center"}}>
            Login
          </p> */}
            <p style={{ paddingTop: "1rem", paddingBottom: "0.5rem", fontWeight: "bold" }}>
              Username</p>
            <TextField
              style={{ backgroundColor: "#e8effd" }}
              error={emailError}
              helperText={emailError ? "Please enter a valid email address" : ''}
              className="text-component"
              hiddenLabel
              id="filled-hidden-label-small"
              size="small"
              value={username}
              placeholder="user@mail.com"
              onChange={(e) => {
                handleUsername(e.target.value);
              }}
              onBlur={(e) => {
                checkEmail(e.target.value);
              }}
            />
            <p style={{ paddingTop: "1rem", paddingBottom: "0.5rem", fontWeight: "bold" }}>
              Password</p>
            <TextField
              style={{ backgroundColor: "#e8effd" }}
              error={passwordError}
              helperText={passwordError ? "Please enter a valid password" : ''}
              className="text-component"
              hiddenLabel
              type="password"
              id="filled-hidden-label-small"
              size="small"
              value={password}
              onChange={(e) => {
                handlePassword(e.target.value);
              }}
              onBlur={(e) => {
                if (password.length === 0) {
                  setPasswordError(true);
                } else {
                  setPasswordError(false);
                }
              }}
            />
            {apiError && (<div style={{ padding: "1rem" }}>
              <Alert severity="error">
                Login failed. Please try again
              </Alert>
            </div>)
            }
            <button style={{ paddingTop: "1rem" }} className="cardButton" onClick={handleSubmit}>
              Login
            </button>
          </div>
          </div>
      </div>
      <div style={{paddingLeft:"10%"}}>
        <p>Please review our privacy policy <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/Privacy.pdf">here</a> and terms of service <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/TOS.pdf">here</a></p><br></br>
        <p><i>This ride service is part of National Science Foundation funded study and is approved by the University of Michigan institutional review board # HUM00166267</i></p>
      </div>
    </div>
  );
};

export default Login;
