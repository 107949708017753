import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
 
const DriverWelcomePage = () => {
    const [checked, setChecked] = useState(false);
    const history = useHistory();
  
    const handleChange = (e) => {
        setChecked(e.target.checked);
      };
    
      const handleNext= () => {
        if (checked!==true){
          return;
        }
        history.push("/driver-profile");
      };
  return (
    <div className="profile">
      <div className="body">
        <p className="heading" style={{ fontWeight: "bold" }}>
          Profile Setup
        </p>
        <br />
        <p className="subHeading">
          Let's setup your profile to help drivers during the trip
        </p>
      </div>

      <div className="profile-home">
        <p style={{ fontSize: "24px", fontWeight: "600" }}>
          Become a Timebank Driver
        </p>
        <div style={{ paddingBottom: "30px" }}></div>
        <div className="driver-info-box">
          <p style={{ fontWeight: "600" }}>
            Driver PowerPoint Presentation
          </p>
          <br />
          <p>
            In this presentation you'll find the guidelines that timebank organizers
            have put together to help new drivers get started.
          </p>
          <br />
          <p style={{ fontWeight: "600" }}>Vehicles</p>
          <br />
          <p>
            You don't need to have your own vehicle to become a driver, but you
            will need to ensure access to one for every trip...
          </p>
          <br />
          <p style={{ fontWeight: "600" }}>Trip Preparation</p>
          <br />
          <p>
            In order to ensure a successful trip, preparation is helpful. Here
            are some tips...
          </p>
          <br />
          <br />
          <FormControlLabel
              control={<Checkbox />}
              label="I have read the driver powerpoint presentation"
              onChange={handleChange}
           />
        </div>
        <div style={{paddingLeft:"50%", paddingTop:"20px"}}>
        <Button 
            variant="contained"
            onClick={handleNext}
            style={{color:"white",background:"#0C66E4",textTransform:"none"}}
          >
            Next
          </Button>
          </div>
      </div>
    </div>
  );
};

export default DriverWelcomePage;
