import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
const DriverTripFeedback = () => {
  const [rating, setRating] = useState("Very Good");
  const history = useHistory();
  try{
    if (window.sessionStorage.getItem("bearerToken")===null){
      history.push("/login");
    };
  }catch(e){
    history.push("/login");
  }

  const handleSelect = (e) => {
    setRating(e.target.value);
  };

  const handleCompleted = (e) => {
    return;
  };

  return (
    <div className="rider-profile-body">
      <p style={{ fontSize: 24, fontWeight: 600 }}>Completed the trip?</p>
      <div className="form-row-1">
        <p>Let us know if the trip is completed</p>
        {/* <Button
          variant="contained"
          style={{
            color: "white",
            background: "#0C66E4",
            textTransform: "none",
          }}
          onClick={handleCompleted}
        >
          Ride completed
        </Button> */}
      </div>
    </div>
  );
};

export default DriverTripFeedback;
