import { useState } from "react";
import { TextField, MenuItem, Select, FormControlLabel, Checkbox, Button, Alert } from "@mui/material";
import Popup from "./Popup";

const RiderTripAccomodations = ({ tripRequest, handleTripRequest }) => {
  const [passengersError, setPassengersError] = useState(false);
  const [selfPopUp, setSelfPopUp] = useState(false);
  const [nullAlert, setNullAlert] = useState(false);

  const [rider, setRider] = useState({
    name: "",
    pronoun: "",
    email: "",
    mobile: "",
    preferredCommunication: "Mobile",
    languages: {
      arabic: false,
      english: false,
      spanish: false,
      other: ""
    },
  });

  const [buddyPopUp, setBuddyPopUp] = useState(false);
  const [contactError, setContactError] = useState(null);
  const checkContact = (value) => {
    if (!/^[0-9]{10}$/.test(value)) {
      setContactError(true);
    } else {
      setContactError(false);
    }
  };

  const [emailError, setEmailError] = useState(null);

  const checkEmail = (value) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const checkMissingValues = () => {
    if (rider.name === "" || contactError || emailError) {
      setNullAlert(true);
      return true;
    }

    setNullAlert(false);
    return false;
  }

  return (
    <div>
      <p style={{ fontSize: 24, fontWeight: 600 }}>Trip Accomodations</p>
      <br></br>
      <br />

      <div>
        <div className="form-row">
          <div className="form-column">
            <p>Would you liked to be matched up with a timebank buddy?<br></br><i>A timebank buddy is someone who can check in with you during your ride</i></p>
            <p>Are you the rider for this trip?            {tripRequest.selfBooking === "No" &&
              <Button style={{ textTransform: "none" }} onClick={() => { setSelfPopUp(true) }}>
                <p style={{ color: "#3579F6" }}>
                  Update Rider Details
                </p>
              </Button>}
            </p>
            <p>How many other people are coming with you on the trip?</p>
          </div>
          <div className="form-column">
            <Select
              className="text-component"
              value={tripRequest.buddyMatchup}
              onChange={(e) => {
                if (e.target.value === "Yes") {
                  setBuddyPopUp(true);
                } else {
                  setBuddyPopUp(false);
                }
                handleTripRequest("buddyMatchup", e.target.value);
              }}
              style={{ height: 40 }}
            >
              <MenuItem value={"Yes"}>Yes</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
            <Popup
              openPopup={buddyPopUp}
            >
              <div>
                Your timebank buddy matchup request will be posted on hOurworld along with your trip request.
                <div
                  style={{
                    paddingTop: "2rem",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setBuddyPopUp(false);
                    }}
                    style={{
                      color: "black",
                      background: "#091E4206",
                      textTransform: "none",
                    }}
                  >
                    Okay
                  </Button>
                </div>
              </div>
            </Popup>
            <Select
              className="text-component"
              value={tripRequest.selfBooking}
              onChange={(e) => {
                handleTripRequest("selfBooking", e.target.value);
                if (e.target.value === "No") {
                  setSelfPopUp(true);
                } else {
                  setSelfPopUp(false);
                }
              }}
              style={{ height: 40 }}
            >
              <MenuItem value={"Yes"}>Yes, I am the rider</MenuItem>
              <MenuItem value={"No"}>No, I am booking this ride for someone else</MenuItem>
            </Select>
            <Popup openPopup={selfPopUp}>
              <div>
                <p className="heading">Rider Details</p>
                <br></br>
                <div style={{ padding: "40px", margin: "1px solid #f8f9fa", backgroundColor: "#f8f9fa" }}>
                  <div className="form-row">
                    <div className="form-column">
                      <p>Name</p>
                      <p>Pronouns</p>
                      <p>Phone Number</p>
                      <p>Email</p>
                      <p>Preferred method of communication</p>
                      <div className="form-column">
                        <p>What languages do you speak?</p>
                        <p>Another language not listed:</p>
                      </div>
                    </div>

                    <div className="form-column">
                      <TextField
                        required
                        id="standard-required"
                        label="Required"
                        size="small"
                        value={rider.name}
                        placeholder="Rider"
                        style={{ height: 30 }}
                        onChange={(e) => {
                          setRider({
                            ...rider,
                            name: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        className="text-component"
                        hiddenLabel
                        id="filled-hidden-label-small"
                        size="small"
                        value={rider.pronoun}
                        placeholder="she/her"
                        style={{ height: 30 }}
                        onChange={(e) => {
                          setRider({
                            ...rider,
                            pronoun: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        className="text-component"
                        required
                        id="standard-required"
                        label="Required"
                        error={contactError}
                        helperText={contactError ? "Please enter a valid 10 digit phone number without any extra characters" : ''}
                        size="small"
                        value={rider.mobile}
                        placeholder="80236498745"
                        style={{ height: 30 }}
                        onChange={(e) => {
                          setRider({
                            ...rider,
                            mobile: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                            checkContact(e.target.value);
                        }}
                      />
                      <TextField
                        error={emailError}
                        helperText={emailError ? "Please enter a valid email address" : ''}
                        className="text-component"
                        required
                        id="standard-required"
                        label="Required"
                        size="small"
                        value={rider.email}
                        placeholder="Email address"
                        style={{ height: 30 }}
                        onChange={(e) => {
                          setRider({
                            ...rider,
                            email: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                            checkEmail(e.target.value);
                        }}
                      />
                      <Select
                        className="text-component"
                        value={rider.preferredCommunication}
                        onChange={(e) => {
                          setRider({
                            ...rider,
                            preferredCommunication: e.target.value,
                          });
                        }}
                        style={{ height: 40 }}
                      >
                        <MenuItem value={"Mobile"}>Mobile</MenuItem>
                        <MenuItem value={"Email"}>Email</MenuItem>
                      </Select>
                      <div className="form-column">
                        <div className="form-row">
                          <FormControlLabel
                            control={<Checkbox checked={rider.languages.english} />}
                            label="English"
                            onChange={(e) => {
                              setRider({
                                ...rider,
                                languages: {
                                  ...rider.languages,
                                  english: e.target.checked,
                                }
                              });
                            }}
                          />
                          <FormControlLabel
                            control={<Checkbox checked={rider.languages.spanish} />}
                            label="Spanish"
                            onChange={(e) => {
                              setRider({
                                ...rider,
                                languages: {
                                  ...rider.languages,
                                  spanish: e.target.checked,
                                }
                              });
                            }}
                          />
                          <FormControlLabel
                            control={<Checkbox checked={rider.languages.arabic} />}
                            label="Arabic"
                            onChange={(e) => {
                              setRider({
                                ...rider,
                                languages: {
                                  ...rider.languages,
                                  arabic: e.target.checked,
                                }
                              });
                            }}
                          />
                        </div>
                        <TextField
                          className="text-component"
                          hiddenLabel
                          id="filled-hidden-label-small"
                          size="small"
                          value={rider.languages.other}
                          placeholder="Greek"
                          onChange={(e) => {
                            setRider({
                              ...rider,
                              languages: {
                                ...rider.languages,
                                other: e.target.value,
                              }
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <br></br>
                  {nullAlert && (
                    <Alert severity="error">{"The rider fields name, email and phone number are required. Please provide valid values"}</Alert>
                  )}
                </div>
                <br></br>
                <div
                  style={{
                    paddingTop: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    gap: "20%"
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (checkMissingValues()) {
                        handleTripRequest("selfBooking", "Yes");
                        setNullAlert(false);
                      }
                      setSelfPopUp(false);
                    }}
                    style={{
                      color: "black",
                      background: "#091E4206",
                      textTransform: "none",
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (checkMissingValues()) {
                        return;
                      }

                      handleTripRequest("riderDetails", rider);
                      setSelfPopUp(false);
                    }}
                    style={{
                      color: "black",
                      background: "#091E4206",
                      textTransform: "none",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Popup>
            <TextField
              error={passengersError}
              helperText={passengersError ? "Give a valid positive number" : ''}
              className="text-component"
              type="number"
              hiddenLabel
              id="filled-hidden-label-small"
              size="small"
              placeholder="Ex: 2"
              value={tripRequest.numberOfPassengers}
              onChange={(e) => {
                handleTripRequest("numberOfPassengers", e.target.value);
              }}
              onBlur={(e) => {
                if (isNaN(parseInt(e.target.value)) || parseInt(e.target.value) < 0 || parseInt(e.target.value) >20) {
                  setPassengersError(true);
                } else {
                  setPassengersError(false);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiderTripAccomodations;
