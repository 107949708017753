import { TextField, Button, FormControlLabel, Checkbox, Select, MenuItem } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useState } from "react";
import ReactRoundedImage from "react-rounded-image";
import Popup from "./Popup";

const DriverProfileInfo = ({
  profile,
  handleProfile,
  imageFile,
  handleImageInput,
  languages,
  handleLanguages,
  vehicleInfo,
  handleVehicleInfo
}) => {

  const [imagePreview, setImagePreview] = useState(null);

  const handleImage = (file) => {
    handleImageInput(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  handleImage(imageFile);

  const removeImage = () => {
    handleImageInput(null);
    setImagePreview(null);
    handleProfile("image", "");
  }

  const fileInputRef = React.createRef();

  const handleFileInput = () => {
    fileInputRef.current.click();
  };

  const [nullErrors, setNullErrors] = useState({})

  const [contactError, setContactError] = useState(null);
  const checkContact = (value) => {
    if (!/^[0-9]{10}$/.test(value)) {
      setContactError(true);
    } else {
      setContactError(false);
    }
  };

  const [popup,setPopup]=useState(false);
  const [date, setDate] = useState(dayjs(vehicleInfo.certificateDate || null));

  return (
    <div className="rider-profile-body">
      <p style={{ fontSize: 24, fontWeight: 600 }}>Setting Up Your Profile</p>
      <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <ReactRoundedImage
          image={imagePreview || profile.image}
          alt="Profile Picture"
          roundedSize="0"
          imageWidth="70"
          imageHeight="70"
        />
        <div className="form-column-1">
          <p>{profile.name}</p>
          <div>
            <Button
              variant="contained"
              onClick={handleFileInput}
              style={{
                color: "white",
                background: "#0C66E4",
                textTransform: "none",
              }}
            >
              Upload your picture
            </Button>
            <input style={{ display: "none" }} ref={fileInputRef} type="file" name="image" onChange={(e) => { handleImage(e.target.files[0]) }} />
            <Button style={{ textTransform: "none" }} onClick={removeImage}>
              <p style={{ fontWeight: "bold", color: "red" }}>Remove picture</p>
            </Button>
            <br></br><br></br>
            <p>Please ignore if you prefer not to post a picture</p>
          </div>
        </div>
      </div>
      <p style={{ fontSize: 24, fontWeight: 600 }}>Driver Information</p>
      <div className="form-row">
        <div className="form-column">
          <p>Pronouns</p>
          <p>Phone Number</p>
          <p>Preferred method of communication</p>
          <div className="form-column">
            <p>What languages do you speak?</p>
            <p>Another language not listed:</p>
          </div>
        </div>

        <div className="form-column">
          <TextField
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={profile.pronoun}
            placeholder="she/her"
            onChange={(e) => {
              handleProfile("pronoun", e.target.value);
            }}
          />
          <TextField
            className="text-component"
            hiddenLabel
            error={contactError}
            helperText={contactError ? "Please enter a valid 10 digit phone number without any extra characters" : ''}
            id="filled-hidden-label-small"
            size="small"
            value={profile.mobile}
            placeholder="80236498745"
            onChange={(e) => {
              handleProfile("mobile", e.target.value);
            }}
            onBlur={(e) => {
              checkContact(e.target.value);
            }}
          />
          <Select
            className="text-component"
            value={profile.preferredCommunication}
            onChange={(e) => {
              handleProfile("preferredCommunication", e.target.value);
              if (e.target.value==="Email"){
                setPopup(true);
              }else{
                setPopup(false);
              }
            }}
            style={{ height: 40 }}
          >
            <MenuItem value={"Phone"}>Phone</MenuItem>
            <MenuItem value={"Text"}>Text</MenuItem>
            <MenuItem value={"Email"}>Email</MenuItem>
          </Select>
          <Popup
              openPopup={popup}
            >
              <div>
                The email you used to logged into the timebank will be used.
                <div
                  style={{
                    paddingTop: "2rem",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setPopup(false);
                    }}
                    style={{
                      color: "black",
                      background: "#091E4206",
                      textTransform: "none",
                    }}
                  >
                    Okay
                  </Button>
                </div>
              </div>
          </Popup>
          {/* <TextField
            error={nullErrors.location}
            helperText={nullErrors.location ? "Location is required" : ''}
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={profile.location}
            placeholder="Bloomington, IN"
            onChange={(e) => {
              handleProfile("location", e.target.value);
            }}
            onBlur={(e)=>{
              isEmpty("location",e.target.value);
            }}
          /> */}
          <div className="form-column">
            <div className="form-row">
              <FormControlLabel
                control={<Checkbox checked={languages.english} />}
                label="English"
                onChange={(e) => {
                  handleLanguages("english", e.target.checked);
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={languages.arabic} />}
                label="Arabic"
                onChange={(e) => {
                  handleLanguages("arabic", e.target.checked);
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={languages.spanish} />}
                label="Spanish"
                onChange={(e) => {
                  handleLanguages("spanish", e.target.checked);
                }}
              />
            </div>
            <TextField
              className="text-component"
              hiddenLabel
              id="filled-hidden-label-small"
              size="small"
              value={languages.other}
              placeholder="Greek"
              onChange={(e) => {
                handleLanguages("other", e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <br></br>
      <div className="form-row">
        <div className="form-column">
          <p>Number of years of driving</p>
          <p>Have you taken the driver training course?</p>
          {vehicleInfo.courseTraining==="Yes" && (<p>Timebank training completion date</p>)}
        </div>
        <div className="form-column">
          <TextField
            error={nullErrors.experience}
            helperText={nullErrors.experience ? "Please enter a valid positive value" : ''}
            type="number"
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="Enter a number"
            value={profile.experience}
            onChange={(e) => {
              handleProfile("experience", e.target.value);
            }}
            onBlur={(e) => {
              if (parseInt(e.target.value) <=0 || parseInt(e.target.value)>100) {
                setNullErrors({
                  experience: true,
                });
              }else{
                setNullErrors({
                  experience: false,
                });
              }
            }}
          />
          <Select
            className="text-component"
            value={vehicleInfo.courseTraining}
            onChange={(e) => {
              handleVehicleInfo("courseTraining", e.target.value);
            }}
            style={{ height: 30 }}
          >
            <MenuItem value={"Yes"}>Yes</MenuItem>
            <MenuItem value={"No"}>No</MenuItem>
          </Select>
          {vehicleInfo.courseTraining==="Yes" && (<LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker slotProps={{ textField: { size: 'small' } }} disableFuture format="MM/DD/YYYY" value={date} onChange={(e) => {
              setDate(e);
              handleVehicleInfo("certificateDate", e.format("YYYY-MM-DD"));
            }} />
          </LocalizationProvider>)}
        </div>
      </div>
    </div>
  );
};

export default DriverProfileInfo;
